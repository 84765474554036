import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Container,
  TablePagination,
  IconButton,
  Button,
} from "@mui/material";
import {
  expenseDocs,
  getAccountDetails,
  getLedgerCreation,
  getOfficeExpense,
  get_all_food_expense,
  get_all_rental_expense,
  get_all_transport_expense,
  get_all_travel_expense,
} from "../../../API_Service/API_Links";
import axios from "axios";
import {
  FilterData,
  FilterEditLedger,
  FilterSumCat,
  FilterTwoData,
} from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";

import LedgerUpdate from "./LedgerUpdate";

export default function EditLedger() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  const [LedgerNameSearch, setLedgerNameSearch] = useState("");
  const [LedgerCategory1Search, setLedgerCategory1Search] = useState("");
  const [LedgerCategory2Search, setLedgerCategory2Search] = useState("");
  const [LedgerCategory3Search, setLedgerCategory3Search] = useState("");
  const [LedgerCategory4Search, setLedgerCategory4Search] = useState("");
  const [LedgerCategory5Search, setLedgerCategory5Search] = useState("");
  const [LedgerGroupsSearch, setLedgerGroupsSearch] = useState("");

  const navigate = useNavigate();

  const getAll = () => {
    axios({
      method: "GET",
      url: getLedgerCreation,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "Id");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (i) => {
    navigate("updateLedger", { state: { data: i } });
  };

  const handleOpenDialog = (i) => {
    setDialogData(i);
    setOpenApproveDialog(true);
  };

  return (
    <Box p={2}>
      <Box boxShadow={4} bgcolor="#EDF4F4" className="cardBoxshadow">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <LedgerUpdate
          getAll={getAll}
          openApproveDialog={openApproveDialog}
          setOpenApproveDialog={setOpenApproveDialog}
          setMessage={setMessage}
          setStatus={setStatus}
          setOpen={setOpen}
          setColor={setColor}
          data={dialogData}
        />
        <Container>
          <Box pt={3} pb={4}>
            <Grid container spacing={1}>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table>
                  <TableHead
                    sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                  >
                    <TableRow>
                      {keys.map((key) => (
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          key={key}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{ my: "auto", textTransform: "uppercase" }}
                            >
                              {key.split(/(?=[A-Z])/).join(" ")}
                            </Box>
                            {key === "LedgerName" && (
                              <Box>
                                <Filter
                                  search={LedgerNameSearch}
                                  setSearch={setLedgerNameSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerGroups" && (
                              <Box>
                                <Filter
                                  search={LedgerGroupsSearch}
                                  setSearch={setLedgerGroupsSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerCategory1" && (
                              <Box>
                                <Filter
                                  search={LedgerCategory1Search}
                                  setSearch={setLedgerCategory1Search}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerCategory2" && (
                              <Box>
                                <Filter
                                  search={LedgerCategory2Search}
                                  setSearch={setLedgerCategory2Search}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerCategory3" && (
                              <Box>
                                <Filter
                                  search={LedgerCategory3Search}
                                  setSearch={setLedgerCategory3Search}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerCategory4" && (
                              <Box>
                                <Filter
                                  search={LedgerCategory4Search}
                                  setSearch={setLedgerCategory4Search}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerCategory5" && (
                              <Box>
                                <Filter
                                  search={LedgerCategory5Search}
                                  setSearch={setLedgerCategory5Search}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        UPDATE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FilterEditLedger(
                      data,
                      LedgerNameSearch,
                      LedgerGroupsSearch,
                      LedgerCategory1Search,
                      LedgerCategory2Search,
                      LedgerCategory3Search,
                      LedgerCategory4Search,
                      LedgerCategory5Search
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          {keys.map((key) => (
                            <TableCell align="center" key={key}>
                              {row[key]}
                            </TableCell>
                          ))}
                          <TableCell align="center">
                            <Button
                              onClick={() => handleOpenDialog(row)}
                              sx={{
                                bgcolor: "#7bc54c",
                                color: "#333",
                                ":hover": { bgcolor: "#616e80" },
                              }}
                            >
                              UPDATE
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  FilterEditLedger(
                    data,
                    LedgerNameSearch,
                    LedgerGroupsSearch,
                    LedgerCategory1Search,
                    LedgerCategory2Search,
                    LedgerCategory3Search,
                    LedgerCategory4Search,
                    LedgerCategory5Search
                  ).length
                }
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
