import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import {
  add_employee_details,
  add_factory_project,
  get_department,
  get_factory_employees,
  getAllEmployeeName,
  getDesignationDynamic,
  getRoleDynamicDropdown,
  methodGet,
  methodPost,
  signup,
} from "../../../API_Service/API_Links";
import { Form } from "react-bootstrap";
import axios from "axios";
import { appendData } from "../../../Variables/Variables";
import SnackBar from "../../SnackBar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  AddBox,
  SystemSecurityUpdateGoodTwoTone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import DropDownDialog from "../../DropDownDialog";

export default function AddTab({
  setValue,
  isAddedCartSnackbarOpen,
  setIsAddedCartSnackbarOpen,
  setNotification,
}) {
  const [showPassword, setShowPassword] = useState(true);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [departmentList, setDepartmentList] = useState([]);
  const [EmployeeType, setEmployeeType] = useState("Factory Employee");
  const [EmployeeId, setEmployeeId] = useState("");
  const [Password, setPassword] = useState("");
  const [UserName, setUserName] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [Profile, setProfile] = useState("");
  const [JoinDate, setJoinDate] = useState("");
  const [GroupCompany, setGroupCompany] = useState("");
  const [Department, setDepartment] = useState("");
  const [Designation, setDesignation] = useState(null);
  const [DesignationList, setDesignationList] = useState([]);
  const [Role, setRole] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [Gender, setGender] = useState("");
  const [Blood, setBlood] = useState("");
  const [AdhaarNo, setAdhaarNo] = useState("");
  const [Age, setAge] = useState("");
  const [DOB, setDOB] = useState("");
  const [Email, setemail] = useState("");
  const [PersonalPh, setPersonalPh] = useState("");
  const [PermanentAddress, setpermanentAddress] = useState("");
  const [PresentAddress, setpresentAddress] = useState("");
  const [Institute, setInstitute] = useState("");
  const [HighLevel, setHighLevel] = useState("");
  const [Course, setCourse] = useState("");
  const [JoinYear, setJoinYear] = useState("");
  const [PassingYear, setPassingYear] = useState("");
  const [Result, setResult] = useState("");
  const [BankName, setBankName] = useState("");
  const [BranchName, setBranchName] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [ConfirmAccountNo, setConfirmAccountNo] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [Holder, setHolder] = useState("");
  const [AdhaarCard, setAdhaarCard] = useState("");
  const [PANCard, setPANCard] = useState("");
  const [DL, setDL] = useState("");
  const [BloodReport, setBloodReport] = useState("");
  const [EducationCert, setEducationCart] = useState("");
  const [EmployeeCV, setEmployeeCV] = useState("");
  const [ExpYear, setExpYear] = useState("");
  const [PANno, setPANno] = useState("");
  const [PassportNo, setPassportNo] = useState("");
  const [DLNumber, setDLNumber] = useState("");
  const [DLExp, setDLExp] = useState("");
  const [DLBatch, setDLBatch] = useState("");
  const [HealthRelated, setHealthRelated] = useState("");
  const [EmergencyName, setEmergencyName] = useState("");
  const [EmergencyContact, setEmergencyContact] = useState("");
  const [Referred, setReferred] = useState("");
  const [ReferralContact, setReferralContact] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMontherName] = useState("");
  const [Marital, setMarital] = useState("");
  const [Spouse, setSpouse] = useState("");
  const [Kid1Name, setKid1Name] = useState("");
  const [Kid2Name, setKid2Name] = useState("");
  const [AdditonalContact, setAdditionalContact] = useState("");
  const [Insurance, setInsurance] = useState("");
  const [ExpLetter, setExpLetter] = useState("");
  const [RelieveLetter, setRelieveLetter] = useState("");
  const [OfferLetter, setOfferLetter] = useState("");
  const [remarks, setRemarks] = useState("");
  const [courseCompletion, setCourseCompletion] = useState("");
  const [Degree, setDegree] = useState("");
  const [ComplainceRemarks, setComplainceRemarks] = useState("");
  const [Complaince, setComplaince] = useState("");
  const [Reporting, setReporting] = useState(null);
  const [reportingPerson, setreportingPerson] = useState(null);
  const [options, setOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [ErrorLine, setErrorLine] = useState("");
  const [errorMsg1, setErrorMsg1] = useState("");
  const [ErrorLine1, setErrorLine1] = useState("");
  const [EmpList, setEmpList] = useState([]);
  const [EmpId, setEmpId] = useState(null);

  const [dropDialogOpen, setDropDialogOpen] = useState(false);

  const [selectedDrop, setSelectedDrop] = useState("");
  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          // setMessage(res.data.message);
          // setOpen(true);
          // setStatus(false);
          // setColor(false);
        } else {
          // setMessage(res.data.message);
          setEmpList(res.data.data);
          // setOpen(true);
          // setStatus(true);
          // setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const getDesignation = () => {
    axios({
      method: "GET",
      url: getDesignationDynamic,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setDesignationList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const getDept = () => {
    axios({
      method: "GET",
      url: get_department,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setDepartmentList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getDept();
    getDesignation();
  }, []);

  useEffect(() => {
    if (Designation !== null && Designation !== "") {
      const sendData = new FormData();
      sendData.append("designationId", Designation?.id);
      axios({
        method: methodPost,
        url: getRoleDynamicDropdown,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setRoleList([]);
          } else {
            setMessage(res.data.message);
            setRoleList(res.data.data);
            setOpen(true);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    } else {
      setRoleList([]);
    }
  }, [Designation]);

  useEffect(() => {
    if (Reporting !== null && Reporting !== "") {
      const sendData = new FormData();
      sendData.append("DepartmentId", Reporting?.DepartmentId);
      axios({
        method: methodPost,
        url: get_factory_employees,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setOptions([]);
          } else {
            setMessage(res.data.message);
            setOptions(res.data.data);
            setOpen(true);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    } else {
      setOptions([]);
    }
  }, [Reporting]);

  const createNewEmployee = () => {
    const serverData = new FormData();
    serverData.append("MobileNum", UserName);
    serverData.append("Password", Password);
    axios({
      method: "POST",
      url: signup,
      data: serverData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setEmployeeId(res.data.EmployeeId);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const handleEmpChange = (event, value) => {
    setEmpId(value);
  };

  const selectedRole = Role?.map((i) => {
    const obj = {
      roleId: i.id,
      role: i.role,
    };
    return obj;
  });

  const selectedDesignation = Designation
    ? [Designation]?.map((i) => {
        const obj = {
          designationId: i.id,
          designation: i.designation,
        };
        return obj;
      })
    : [];

  const serverData = {
    employeeName: EmployeeName,
    EmployeeId: EmployeeId,
    email: Email,
    employeeType: EmployeeType,
    department: Department,
    designation: JSON.stringify(selectedDesignation),
    roles: JSON.stringify(selectedRole),
    gender: Gender,
    bloodGroup: Blood,
    aadharNum: AdhaarNo,
    age: Age,
    groupCompany: GroupCompany,
    dateOfBirth: DOB,
    dateOfJoining: JoinDate,
    personalPhoneNum: PersonalPh,
    presentAddress: PresentAddress,
    permanentAddress: PermanentAddress,
    instituteName: Institute,
    highestLevel: HighLevel,
    course: Course,
    yearOfJoining: JoinYear,
    yearOfPassing: PassingYear,
    result: Result,
    aadharCard: AdhaarCard,
    panCard: PANCard,
    drivingLicense: DL,
    bloodReport: BloodReport,
    educationalCertificate: EducationCert,
    employeeCV: EmployeeCV,
    insurance: Insurance,
    experienceLetter: ExpLetter,
    relievingLetter: RelieveLetter,
    offerLetter: OfferLetter,
    bankName: BankName,
    branchName: BranchName,
    accountNo: AccountNo,
    confirmAccountNo: ConfirmAccountNo,
    IFSCcode: IFSC,
    accountHolder: Holder,
    yearsOfExperience: ExpYear,
    panNum: PANno,
    passportNum: PassportNo,
    drivingLicenseNum: DLNumber,
    drivingLicenseExpiration: DLExp,
    drivingLicenseBatch: DLBatch,
    healthIssues: HealthRelated,
    emergencyContactName: EmergencyName,
    emergencyContactNum: EmergencyContact,
    employeeReferredBy: Referred,
    referralContactNum: ReferralContact,
    fatherName: FatherName,
    motherName: MotherName,
    maritalStatus: Marital,
    spouseName: Spouse,
    kidsName1: Kid1Name,
    kidsName2: Kid2Name,
    additionalContactDetails: AdditonalContact,
    EmployeeId: EmployeeId,
    isCourseCompleted: courseCompletion,
    isComplaince: Complaince,
    complainceRemarks: ComplainceRemarks,
    degree: Degree,
    profileImage: Profile,
    reportingTo: Reporting?.DepartmentId,
    reportingPerson: reportingPerson?.EmployeeId,
    remarks: remarks,
    active: 1,
  };
  const sendData = appendData(serverData);
  const onSubmit = () => {
    if (!navigator.onLine) {
      setMessage("Your internet is in Offline");
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      axios({
        method: methodPost,
        url: add_employee_details,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setIsAddedCartSnackbarOpen(true);
            setNotification(res.data.message);
            setValue("2");
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const cancelClick = () => {
    navigate("/home/HRMS");
  };

  const resetData = () => {
    setEmployeeId("");
    setPassword("");
    setUserName("");
    setEmployeeName("");
    setProfile("");
    setJoinDate("");
    setGroupCompany("");
    setDepartment("");
    setDesignation("");
    setRole("");
    setGender("");
    setBlood("");
    setAdhaarNo("");
    setAge("");
    setDOB("");
    setemail("");
    setPersonalPh("");
    setpermanentAddress("");
    setpresentAddress("");
    setInstitute("");
    setHighLevel("");
    setCourse("");
    setJoinYear("");
    setPassingYear("");
    setResult("");
    setBankName("");
    setBranchName("");
    setAccountNo("");
    setConfirmAccountNo("");
    setIFSC("");
    setHolder("");
    setAdhaarCard("");
    setPANCard("");
    setDL("");
    setBloodReport("");
    setEducationCart("");
    setEmployeeCV("");
    setExpYear("");
    setPANno("");
    setPassportNo("");
    setDLNumber("");
    setDLExp("");
    setDLBatch("");
    setHealthRelated("");
    setEmergencyName("");
    setEmergencyContact("");
    setReferred("");
    setReferralContact("");
    setFatherName("");
    setMontherName("");
    setMarital("");
    setSpouse("");
    setKid1Name("");
    setKid2Name("");
    setAdditionalContact("");
    setInsurance("");
    setExpLetter("");
    setRelieveLetter("");
    setOfferLetter("");
    setRemarks("");
    setCourseCompletion("");
    setDegree("");
    setComplainceRemarks("");
    setComplaince("");
    setReporting(null);
    setreportingPerson(null);
  };

  const handleOpenDialog = (field) => {
    switch (field) {
      case "Role":
        setDropDialogOpen(true);
        setSelectedDrop("Role");
        break;
      case "Designation":
        setDropDialogOpen(true);
        setSelectedDrop("Designation");
        break;
      case "Department":
        setDropDialogOpen(true);
        setSelectedDrop("Department");
        break;
      default:
    }
  };

  return (
    <Box>
      <Box sx={{ height: "90%" }} display="flex" alignItems="center">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <DropDownDialog
          dropDialogOpen={dropDialogOpen}
          setDropDialogOpen={setDropDialogOpen}
          selectedDrop={selectedDrop}
          setSelectedDrop={setSelectedDrop}
          DesignationList={DesignationList}
          setMessage={setMessage}
          setColor={setColor}
          setStatus={setStatus}
          setOpen={setOpen}
          getDesignation={getDesignation}
          getDept={getDept}
        />
        <Box
          py={4}
          sx={{
            px: 5,
            backgroundColor: "#EDF4F4",
            borderRadius: "10px",
            mx: 2,
            my: 4,
            boxShadow: 11,
          }}
        >
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  "Factory Employee",
                  "Site Helpers",
                  "Site Employee",
                  "Office Employee",
                ]}
                onChange={(index, value) => setEmployeeType(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee Type"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
              />
            </Grid>
          </Grid>

          {EmployeeType !== "Site Helpers" ? (
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    mt: 5,
                    px: 2,
                    border: "1px solid black",
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                  }}
                >
                  <Box sx={{ px: 2, textAlign: "left" }}>
                    <h5>CREATE ACCOUNT</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Mobile Number"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        autoComplete="newNumber"
                        inputProps={{
                          maxLength: 10,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setUserName(numericValue);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        type={showPassword ? "password" : "text"}
                        label="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          errors.pass && errors.pass.type === "required"
                            ? "Password is required"
                            : ""
                        }
                        error={errors.pass ? true : false}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={14}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <Button
                        sx={{ p: 2, color: "white" }}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={createNewEmployee}
                      >
                        CREATE EMPLOYEE ACCOUNT
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/* Personal Info */}

                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 1,
                  }}
                >
                  <Box sx={{ pb: 2, textAlign: "left" }}>
                    <h5>BASIC INFORMATION</h5>
                  </Box>
                  <Grid container justifyContent="start" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Employee ID"
                        variant="outlined"
                        size="small"
                        disabled
                        color="secondary"
                        value={EmployeeId}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Employee Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        error={ErrorLine}
                        helperText={errorMsg}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (!newValue.match(/[<>$'"]/)) {
                            setErrorMsg("");
                            setErrorLine(false);
                            setEmployeeName(newValue); // only set when successful
                          } else {
                            setErrorLine(true);
                            setErrorMsg("Forbidden character: %<>$'\"");
                          }
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Profile Picture"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        error={ErrorLine1}
                        helperText={errorMsg1}
                        accept="image/*" // Accept all image types
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          if (
                            selectedFile &&
                            selectedFile.type.startsWith("image/")
                          ) {
                            setProfile(selectedFile);
                            setErrorLine1(false);
                          } else {
                            setErrorMsg1("Unacceptable File Type");
                            setErrorLine1(true);
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Joining"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setJoinDate(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          "IGreen Technologies",
                          "IGreentec engineering. India Pvt. Ltd",
                        ]}
                        value={GroupCompany}
                        onChange={(event, value) => setGroupCompany(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Group Company"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                        sx={{ width: "100%" }}
                        alignItems="center"
                        alignContent="center"
                      >
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={departmentList.map((i) => i.Department)}
                          onChange={(event, value) => setDepartment(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Department"
                              sx={{ width: 180 }}
                              variant="outlined"
                              size="small"
                              color="secondary"
                            />
                          )}
                        />
                        <AddBox
                          sx={{ verticalAlign: "bottom", cursor: "pointer" }}
                          onClick={() => handleOpenDialog("Department")}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                        sx={{ width: "100%" }}
                        alignItems="center"
                        alignContent="center"
                      >
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={DesignationList}
                          onChange={(event, value) => setDesignation(value)}
                          getOptionLabel={(option) => option.designation}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Designation"
                              sx={{ width: 160 }}
                              variant="outlined"
                              size="small"
                              color="secondary"
                            />
                          )}
                        />
                        <AddBox
                          sx={{ verticalAlign: "bottom", cursor: "pointer" }}
                          onClick={() => handleOpenDialog("Designation")}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                        sx={{ width: "100%" }}
                        alignItems="center"
                        alignContent="center"
                      >
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={RoleList}
                          multiple
                          onChange={(event, value) => setRole(value)}
                          getOptionLabel={(option) => option.role}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Role"
                              sx={{ width: 160 }}
                              variant="outlined"
                              size="small"
                              color="secondary"
                            />
                          )}
                        />
                        <AddBox
                          sx={{ verticalAlign: "bottom", cursor: "pointer" }}
                          onClick={() => handleOpenDialog("Role")}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Reporting}
                        options={departmentList}
                        freeSolo
                        getOptionLabel={(option) => option.Department}
                        onChange={(event, value) => setReporting(value)}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Superior Department"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={EmpList}
                        value={reportingPerson}
                        getOptionLabel={(option) =>
                          `${option.EmployeeName} (${option.EmployeeId})`
                        }
                        onChange={(event, value) => setreportingPerson(value)}
                        renderInput={(params) => (
                          <TextField
                            color="secondary"
                            label="Employee Name"
                            {...params}
                            required
                          />
                        )}
                      />
                      {/* <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={reportingPerson}
                          options={options}
                          freeSolo
                          onChange={(event, value) => setreportingPerson(value)}
                          getOptionLabel={(option) => option.EmployeeName}
                          renderInput={(params) => (<TextField {...params} label="Reporting To"
                            sx={{ width: "100%" }} variant="outlined"
                            size="small" color="secondary"
                          />
                          )}
                        /> */}
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["Male", "Female", "Prefer Not to Say"]}
                        value={Gender}
                        onChange={(event, value) => setGender(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gender"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Blood Group"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setBlood(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Adhaar No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 12,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setAdhaarNo(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Age"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        inputProps={{
                          maxLength: 3,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setAge(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Birth"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Email ID"
                        type="email"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Years Of Exp."
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        onChange={(e) => setExpYear(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Personal Mobile No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 10,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setPersonalPh(numericValue);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["Complaince", "Non-Complaince"]}
                        onChange={(event, value) => setComplaince(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Complaince/Non-Complaince"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Complaince Remarks"
                        variant="outlined"
                        type="tel"
                        size="small"
                        color="secondary"
                        onChange={(e) => setComplainceRemarks(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={1}>
                      <Box sx={{ textAlign: "left" }}>
                        <h5>ADDRESS INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 1 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Permanent Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setpermanentAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Present Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setpresentAddress(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>EDUCATION INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Name Of the Institute"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setInstitute(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Highest Level"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setHighLevel(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Course/Stream"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setCourse(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["YES", "NO"]}
                        size="small"
                        onChange={(e, value) => setCourseCompletion(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Course Completion"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          "UG",
                          "PG",
                          "10th grade/SSLC",
                          "12th grade/HSC",
                        ]}
                        onChange={(event, value) => setDegree(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Highest Qualification"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    {/* <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Year Of Joining"
                        variant="outlined"
                        size="small"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setJoinYear(e.target.value)}
                      />
                    </Grid> */}

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Year Of Passing"
                        variant="outlined"
                        size="small"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        color="secondary"
                        onChange={(e) => setPassingYear(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="lastName"
                        label="Result"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setResult(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* (Financial Info) */}

              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    px: 4,
                    pb: 5,
                    pt: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>BANKING INFORMATION</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Account Holder Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setHolder(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Bank Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setBankName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Account No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setAccountNo(numericValue);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Confirm Account No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setConfirmAccountNo(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setIFSC(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Branch Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={5}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>PROOF DOCUMENTS</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Adhaar"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setAdhaarCard(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="PAN"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setPANCard(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Driver's License"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setDL(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Blood Report"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setBloodReport(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Educational Cert"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setEducationCart(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Employee CV"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setEmployeeCV(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={5}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>PERSONAL INFORMATION</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidt
                        label="PAN No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{10,10}",
                          maxLength: 10,
                          minLength: 10,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setPANno(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Passport No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setPassportNo(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Driving License No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setDLNumber(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Driving License Expiration"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDLExp(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Driving License Batch"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setDLBatch(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Health Related Issues"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setHealthRelated(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Emergency ContactName"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setEmergencyName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Emergency Contact No."
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 10,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setEmergencyContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Employee Referred By"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setReferred(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Referral Contact No."
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 10,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setReferralContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Father's Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setFatherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Mother's Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setMontherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Marital Status"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setMarital(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Spouse Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setSpouse(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Kid1 Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setKid1Name(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Kid2 Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setKid2Name(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                      md={12}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidt
                        label="Additional Contact details"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          maxLength: 10,
                          type: "text",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setAdditionalContact(e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={12} sm={12} xl={12} xs={12} md={12} mt={3}>
                      <Box sx={{ pb: 1, textAlign: "left" }}>
                        <h5>OFFER LETTER INFO</h5>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Insurance"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setInsurance(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Experience Lettter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setExpLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Relieving Letter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setRelieveLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="projectdocument"
                        label="Offer Letter"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="file"
                        onChange={(e) => setOfferLetter(e.target.files[0])}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: "center" }}
              spacing={4}
            >
              <Grid item lg={6} xl={6}>
                {/* Personal Info */}

                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>SITE HELPER INFORMATION 1</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Employee Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => {
                          const sanitizedValue = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          ); // Allow only alphabetic characters and spaces
                          setEmployeeName(sanitizedValue);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Employee ID"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={DesignationList.map((i) => i.designation)}
                        onChange={(event, value) => setDesignation(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Designation"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={departmentList.map((i) => i.Department)}
                        onChange={(event, value) => setDepartment(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Department"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={Reporting}
                        options={departmentList}
                        freeSolo
                        getOptionLabel={(option) => option.Department}
                        onChange={(event, value) => setReporting(value)}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Superior Department"
                            sx={{ width: "100%" }}
                            variant="outlined"
                            size="small"
                            color="secondary"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={EmpList}
                        value={reportingPerson}
                        getOptionLabel={(option) =>
                          `${option.EmployeeName} (${option.EmployeeId})`
                        }
                        onChange={(event, value) => setreportingPerson(value)}
                        renderInput={(params) => (
                          <TextField
                            color="secondary"
                            label="Employee Name"
                            {...params}
                            required
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Age"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{1,3}",
                          maxLength: 3,
                          minLength: 1,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setAge(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="Services"
                        type="date"
                        label="Date Of Birth"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWid
                        label="Personal Mobile No"
                        variant="outlined"
                        size="small"
                        type="tel"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{10,10}",
                          maxLength: 10,
                          minLength: 10,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setPersonalPh(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Permanent Address"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setpermanentAddress(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item lg={6} xl={6}>
                <Box
                  sx={{
                    border: "1px solid black",
                    p: 4,
                    borderColor: "#d2cbcb;",
                    borderRadius: "4px",
                    ":hover": { boxShadow: 2 },
                    mt: 5,
                  }}
                >
                  <Box sx={{ pb: 3, textAlign: "left" }}>
                    <h5>SITE HELPER INFORMATION 2</h5>
                  </Box>
                  <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Adhaar No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{10,12}",
                          maxLength: 12,
                          minLength: 10,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setAdhaarNo(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWid
                        label="PAN No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{10,10}",
                          maxLength: 10,
                          minLength: 10,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setPANno(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Blood Group"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setBlood(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Health Related Issues"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setHealthRelated(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Father's Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setFatherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Mother's Name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setMontherName(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        label="Marital Status"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setMarital(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={4}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWid
                        label="Emergency Contact No"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={{
                          pattern: "[0-9]{10,10}",
                          maxLength: 10,
                          minLength: 10,
                        }}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          setEmergencyContact(numericValue);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={4}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidt
                        label="Additional Contact details"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setAdditionalContact(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      sm={4}
                      xl={4}
                      xs={14}
                      md={4}
                      sx={{ py: 2 }}
                    >
                      <TextField
                        fullWidth
                        id="firstName"
                        label="Remarks"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          {/* {buttons}  */}

          <Grid
            container
            justifyContent="center"
            sx={{ textAlign: "center", mt: 3 }}
          >
            <Grid item lg={6} xl={6} xs={12}>
              <Grid container justifyContent="space-evenly" alignItems="center">
                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      type="submit"
                      onClick={onSubmit}
                      sx={{
                        color: "white",
                        backgroundColor: "#7bc54c",
                        borderColor: "#7bc54c",
                        ":hover": { borderColor: "#7bc54c", color: "#000000" },
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
                <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={cancelClick}
                      type="cancel"
                      sx={{
                        color: "white",
                        backgroundColor: "#c62828",
                        borderColor: "#c62828",
                        ":hover": { borderColor: "#c62828", color: "#000000" },
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
