import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Card,
  Button,
  TableFooter,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Container,
  TablePagination,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import {
  expenseDocs,
  fetch_expense_category,
  getAllEmployeeName,
  getAllLedgers,
  getProjectCode,
  get_all_maintenance_expense,
  get_all_rental_expense,
  get_expense_summary,
  get_summary_Report,
  methodGet,
  methodPost,
} from "../../../API_Service/API_Links";
import axios from "axios";
import {
  FilterData,
  FilterFourData,
  FilterLedger,
} from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import { PlayCircleFilled, Image } from "@mui/icons-material";
import SnackBar from "../../SnackBar";
import { ExcelExport } from "../../../Export/ExportExcel";
import { LedgerExport } from "./LedgerExport";

export default function ViewLedger() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const [ExportSummary, setExportSummary] = useState([]);
  const [EmployeeName, setEmployeeName] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [SelectedProjectCode, setSelectedProjectCode] = useState("");
  const [SelectedCategory, setSelectedCategory] = useState("");
  const [spendDate, setSpendDate] = useState("");

  const [LedgerNameSearch, setLedgerNameSearch] = useState("");
  const [employeeNameSearch, setEmployeeNameSearch] = useState("");
  const [projectCodeSearch, setProjectCodeSearch] = useState("");
  const [categorySearch, setcategorySearch] = useState("");
  const [dateSearch, setdateSearch] = useState("");
  const [LedgerGroupsSearch, setLedgerGroupsSearch] = useState("");

  const [empname, setEmpname] = useState([]);
  const [projectCodeList, setprojectCodeList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [isFilterOn, setIsFilterOn] = useState(false);

  const [allSummary, setAllSummary] = useState([]);
  const [dateError, setDateError] = useState("");

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageDialog = (data) => {
    setOpenImageDialog(!openImageDialog);
    setSelectedImage(data);
  };

  useEffect(() => {
    axios({
      method: methodGet,
      url: getProjectCode,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setprojectCodeList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmpname(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, []);

  const getAllSummary = () => {
    const sendData = new FormData();
    sendData.append("EmployeeId", "");
    sendData.append("FromDate", "");
    sendData.append("ToDate", "");
    sendData.append("ProjectCode", "");
    sendData.append("Category", "");
    axios({
      method: methodPost,
      url: getAllLedgers,
      data: sendData,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setOpen(true);
          setAllSummary(res.data.data);
          setIsFilterOn(false);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllSummary();
  }, []);

  const getFilteredSummary = () => {
    const sendData = new FormData();
    sendData.append("EmployeeId", EmployeeId);
    sendData.append("FromDate", fromDate);
    sendData.append("ToDate", toDate);
    sendData.append("ProjectCode", SelectedProjectCode);
    sendData.append("Category", SelectedCategory);
    axios({
      method: methodPost,
      url: getAllLedgers,
      data: sendData,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setOpen(true);
          setAllSummary(res.data.data);
          setIsFilterOn(true);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const keys = Object.keys(allSummary[0] || {});

  const options =
    projectCodeList &&
    projectCodeList.map((i, index) => {
      const obj = {
        label: i.projectCode,
        id: index,
      };
      return obj;
    });

  const options1 =
    categoryList &&
    categoryList.map((i, index) => {
      const obj = {
        label: i.ExpenseCategoryName,
        id: i.ExpenseCategoryId,
      };
      return obj;
    });

  return (
    <Box p={2}>
      <Box bgcolor="#EDF4F4" py={2} className="cardBoxshadow">
        <Dialog
          open={openImageDialog}
          onClose={() => setOpenImageDialog(false)}
        >
          <img src={`${expenseDocs}${selectedImage}`} alt="Fuel" />
        </Dialog>
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <Container>
          <Box component={Card} p={4}>
            <Grid container spacing={3} justifyContent="start">
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                  <Autocomplete
                    clearText="Clear"
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={empname}
                    getOptionLabel={(option) =>
                      `${option.EmployeeName} (${option.EmployeeId})`
                    }
                    onChange={(event, value) =>
                      setEmployeeId(value ? value.EmployeeId : "")
                    }
                    renderInput={(params) => (
                      <TextField
                        color="secondary"
                        label="Employee Name"
                        {...params}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    id="EmployeeName"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    value={fromDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                  <TextField
                    id="EmployeeName"
                    label="To Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="secondary"
                    value={toDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setToDate(e.target.value)}
                    inputProps={{ min: fromDate }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    value={SelectedProjectCode}
                    onChange={(event, value) =>
                      setSelectedProjectCode(value ? value.label : "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project Code"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box mt={1}>
                  <TextField
                    name="category"
                    label="Category"
                    fullWidth
                    value={SelectedCategory}
                    size="small"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={3} lg={3}>
                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={getFilteredSummary}
                    variant="contained"
                    sx={{ bgcolor: "secondary.main", my: 1 }}
                  >
                    FILTER SUMMARY
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Box display="flex" justifyContent="center">
                  <LedgerExport
                    fileName={"All Ledger Report"}
                    csvData={allSummary}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Container>
          <Box mt={2} py={4}>
            <Grid container spacing={1}>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table>
                  <TableHead
                    sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                  >
                    <TableRow>
                      {keys.map((key) => (
                        <TableCell
                          align="center"
                          sx={{
                            color: "white",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          key={key}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{ my: "auto", textTransform: "uppercase" }}
                            >
                              {key.split(/(?=[A-Z])/).join(" ")}
                            </Box>
                            {key === "LedgerName" && (
                              <Box>
                                <Filter
                                  search={LedgerNameSearch}
                                  setSearch={setLedgerNameSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "employeeName" && (
                              <Box>
                                <Filter
                                  search={employeeNameSearch}
                                  setSearch={setEmployeeNameSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "ProjectCode" && (
                              <Box>
                                <Filter
                                  search={projectCodeSearch}
                                  setSearch={setProjectCodeSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "Category1" && (
                              <Box>
                                <Filter
                                  search={categorySearch}
                                  setSearch={setcategorySearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "ExpenseDate" && (
                              <Box>
                                <Filter
                                  search={dateSearch}
                                  setSearch={setdateSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "LedgerGroups" && (
                              <Box>
                                <Filter
                                  search={LedgerGroupsSearch}
                                  setSearch={setLedgerGroupsSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FilterLedger(
                      allSummary,
                      LedgerNameSearch,
                      employeeNameSearch,
                      projectCodeSearch,
                      categorySearch,
                      dateSearch,
                      LedgerGroupsSearch
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          {keys.map((key) => (
                            <TableCell align="center" key={key}>
                              {row[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  FilterLedger(
                    allSummary,
                    LedgerNameSearch,
                    employeeNameSearch,
                    projectCodeSearch,
                    categorySearch,
                    dateSearch,
                    LedgerGroupsSearch
                  ).length
                }
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
