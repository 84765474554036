import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { add_amount, getBankDropdown, reject_leave } from '../../API_Service/API_Links';
import { useState } from 'react';
import axios from 'axios';
import { approve_notify, getPendingFundRequest, methodPost } from "../../API_Service/API_Links";
import { appendData } from '../../Variables/Variables';
import { useEffect } from 'react';

export default function FundApproveDialog({
  setId,
  setopenApproveDialog,
  openApproveDialog,
  setIsAddedCartSnackbarOpen,
  setNotification,
  getAllPendinRequest ,
  id,
  setSelectedProjectList,
  selectedProjectList ,
  setSelectedEmployeeId,
  SelectedEmployeeId
  }) {

  const handleClose = () => {
    setopenApproveDialog(false);
    setId('');
    setSelectedEmployeeId('')
    setSelectedProjectList('')
    setApprovedAmount('')
    setRemarks('');
  };

  const [Remarks, setRemarks] = useState('');
  const [ApprovedAmount, setApprovedAmount] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const SendEmployee = JSON.parse(localStorage.getItem('EmployeeId'));
  const [Account , setAccount] = useState([])
  const [SelectedAccount , setSelectedAccount] = useState(null)
  const [AccountHolder , setAccountHolder] = useState(null)

  useEffect(()=>{
    if(AccountHolder !== '' && AccountHolder !== null && AccountHolder !== undefined){
    const sendData = new FormData()
    sendData.append('AccountHolder' , AccountHolder)
    axios({
         method:'POST',
         url: getBankDropdown,
         data:sendData,
     }).then(res => {
         if (res.data.error) {
             setMessage(res.data.message)
             setOpen(true)
             setStatus(false)
             setColor(false)
             setAccount([])
         } else {
             setMessage(res.data.message)
             setOpen(true)
             setStatus(true)
             setColor(true)
             setAccount(res.data.data);
         }
     }).catch(err => {
         alert('Oops something went wrong ' + err)
     });
    }
 },[AccountHolder]);

  const approvereq = () => {
    const sendData = new FormData()
    sendData.append('notificationId', id)
    sendData.append('reason', Remarks)
    sendData.append('amount', ApprovedAmount)
    sendData.append('EmployeeId', SelectedEmployeeId)
    sendData.append('AccountHolder', AccountHolder)
    sendData.append('SendEmployee', SendEmployee)
    sendData.append('accountDetailsId', SelectedAccount?.accountDetailsId)
    axios({
        method: methodPost,
        url: approve_notify,
        data:sendData
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true)
            setStatus(false)
            setColor(false)
          } else {
            setMessage(res.data.message);
            setOpen(false)
            setStatus(false)
            setColor(false)
            AddMoneyToWallet();
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
   }

  const serverData = {
    EmployeeId: SelectedEmployeeId,
    ProjectName: selectedProjectList,
    Descriptions: '',
    Amount: ApprovedAmount,
    ImageProof: '',
   accountDetailsId : SelectedAccount?.accountDetailsId,
  }
  const sendData = appendData(serverData);
  const AddMoneyToWallet = () => {
    axios({
      method: 'POST',
      url: add_amount,
      data: sendData
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setMessage(res.data.message)
        setOpen(false)
        setStatus(true)
        setColor(true)
        handleClose();
        setIsAddedCartSnackbarOpen(true)
        setNotification('Request Approved and Amount Added')
        getAllPendinRequest();
      }
    }).catch(err => {
      alert('Oops something went wrong ' + err)
    });
  }


  return (
    <div>
      <Dialog
        open={openApproveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle>Approve This Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Apporved Amount and Your Remarks to Approve this Fund Request.
          </DialogContentText>
          <Stack spacing={2} mt={3}>
           <TextField
            autoFocus
            id="name"
            fullWidth
            variant="outlined"
            label='Approved Amount'
            InputLabelProps={{
              shrink:true
            }}
            inputProps={{
              maxLength: 7,
              type: 'text',
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            onChange={(e)=>setApprovedAmount(e.target.value)} x
          />


      <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={AccountHolder}
      options={['igreen', 'igreentec']}
      onChange={(event, value) =>setAccountHolder(value)}
      size='small'
      renderInput={(params) => <TextField fontSize='small' label='Account Holder' color='secondary' {...params} />}
        />
   


      <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={SelectedAccount}
      options={Account}
      getOptionLabel={(option)=> `${option.account_number}(${option.AliasName})`}
      onChange={(event, value) =>setSelectedAccount(value)}
      size='small'
      renderInput={(params) => <TextField fontSize='small' label='Account' color='secondary' {...params} />}
        />
 


           <TextField
            id="name"
            fullWidth
            variant="outlined"
            label='Remarks'
              InputLabelProps={{
                shrink: true
              }}
            onChange={(e)=>setRemarks(e.target.value)}
          />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={approvereq}>Approve</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}