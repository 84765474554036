import { baseApiurl } from "./BaseUrl";

const endPoint = baseApiurl.Prod;

//product image path
const FactoryDocs = `${endPoint}factory_project/`;

const ProfileDocs = `${endPoint}profile/`;

const SiteDocs = `${endPoint}site_project/`;

const QuoteDocs = `${endPoint}vendorquotation/`;

const expenseDocs = `${endPoint}expense/`;

const fundRequest = `${endPoint}fund_request/`;

const reminder = `${endPoint}reminder/`;

const bankimages = `${endPoint}bankimages/`;

// get method
const methodGet = "GET";

// post method
const methodPost = "POST";

//Add Customer Details and project details
const add_factory_project = `${endPoint}add_factory_project`;

//get order Details
const getorderdetails = `${endPoint}getallproject`;

//get Product Details
const getproductdetails = `${endPoint}getallproduct`;

//Login api
const login = `${endPoint}login`;

// add products
const addproduct = `${endPoint}addproduct`;

// delete products
const deleteproduct = `${endPoint}deleteproduct`;

//get single project

const getsingleproject = `${endPoint}getproject`;

//edit product

const editproduct = `${endPoint}updateproduct`;

//get product
const getproduct = `${endPoint}getproduct`;

//update Assigned projects
const updateassignedproject = `${endPoint}updateassignedproject`;

//assign project
const assignproject = `${endPoint}assignproject`;

//add vendor
const addvendor = `${endPoint}addvendor`;

//Procurement
const procurement = `${endPoint}addquotation`;

//get assigned orders
const getassignedorders = `${endPoint}getassignedproject`;

//get assigned orders
const addexpense = `${endPoint}addexpense`;

//get assigned orders
const deleteexpense = `${endPoint}deleteexpense`;

//get assigned orders
const getallexpense = `${endPoint}getallexpense`;

//get assigned orders
const updateexpense = `${endPoint}updateexpense`;

//get assigned orders
const getexpense = `${endPoint}getexpense`;

const siteClient = `${endPoint}get_client`;

const siteState = `${endPoint}get_state`;

const siteDistrict = `${endPoint}get_district`;

const create_project_code = `${endPoint}create_project_code`;

const factorydepartment = `${endPoint}factorydepartment`;

const get_factory_employees = `${endPoint}get_factory_employees`;

const get_all_factory_project = `${endPoint}get_all_factory_project`;

const update_factory_project = `${endPoint}update_factory_project`;

const apply_leave = `${endPoint}apply_leave`;

const get_user_leave = `${endPoint}get_user_leave`;

const getall_applied_leave = `${endPoint}getall_applied_leave`;

const update_tender_details = `${endPoint}update_tender_details`;

const getAllSiteProject = `${endPoint}getAllSiteProject`;

const updateSiteProject = `${endPoint}update_site_project`;

const getParticularSiteProject = `${endPoint}getParticularSiteProject`;

const getFlowOfBill = `${endPoint}getFlowOfBill`;

const getDeduction = `${endPoint}getDeduction`;

const get_all_fuel_expense = `${endPoint}get_all_fuel_expense`;

const get_all_accommodation_expense = `${endPoint}get_all_accommodation_expense`;

const get_all_rental_expense = `${endPoint}get_all_rental_expense`;

const get_all_travel_expense = `${endPoint}get_all_travel_expense`;

const get_all_transport_expense = `${endPoint}get_all_transport_expense`;

const get_all_food_expense = `${endPoint}get_all_food_expense`;

const get_all_maintenance_expense = `${endPoint}get_all_maintenance_expense`;

const get_all_general_expense = `${endPoint}get_all_general_expense`;

const get_all_wages_expense = `${endPoint}get_all_wages_expense`;

const get_all_purchases_expense = `${endPoint}get_all_purchases_expense`;

const get_all_utilization_expense = `${endPoint}get_all_utilization_expense`;

const get_all_others_expense = `${endPoint}get_all_others_expense`;

const approve_leave = `${endPoint}approve_leave`;

const get_all_pending_leaves = `${endPoint}get_all_pending_leaves`;

const reject_leave = `${endPoint}reject_leave`;

const get_all_view_leaves = `${endPoint}get_all_view_leaves`;

const signup = `${endPoint}signup`;

const add_employee_details = `${endPoint}add_employee_details`;

const get_department = `${endPoint}get_department`;

const get_employee_detail = `${endPoint}get_employee_detail`;

const get_all_escalation = `${endPoint}get_all_escalation`;

const escalation_approval = `${endPoint}escalation_approval`;

const get_employee_id = `${endPoint}get_employee_id`;

const getTask = `${endPoint}getTask`;

const insertTask = `${endPoint}insertTask`;

const getAllEmployeeName = `${endPoint}getAllEmployeeName`;

const update_task_details = `${endPoint}update_task_details`;

const getParticularTask = `${endPoint}getParticularTask`;

const add_announcement = `${endPoint}add_announcement`;

const getallannouncement = `${endPoint}getall_message`;

const getAllFundRequest = `${endPoint}getAllFundRequest`;

const getPendingFundRequest = `${endPoint}getPendingFundRequest`;

const approve_notify = `${endPoint}approve_notify`;

const reject_notify = `${endPoint}reject_notify`;

const add_employee_salary = `${endPoint}add_employee_salary`;

const add_incentives = `${endPoint}add_incentives`;

const add_deductions = `${endPoint}add_deductions`;

const get_all_ctc = `${endPoint}get_all_ctc`;

const get_expense_summary = `${endPoint}get_expense_summary`;

const get_summary_Report = `${endPoint}get_overall_excel_expense_summary`;

const get_employee_name = `${endPoint}get_employee_name`;

const addincentives = `${endPoint}addincentives`;

const get_available_balance = `${endPoint}get_available_balance`;

const add_amount = `${endPoint}add_amount`;

const get_factory_project = `${endPoint}get_factory_project`;

const get_employee_pay_slip = `${endPoint}get_employee_pay_slip`;

const get_project_manager = `${endPoint}get_project_manager`;

const add_raw_material_availability = `${endPoint}add_raw_material_availability`;

const get_project_for_purchase = `${endPoint}get_project_for_purchase`;

const add_purchase_quotation = `${endPoint}add_purchase_quotation`;

const get_project_for_store = `${endPoint}get_project_for_store`;

const deleteuser = `${endPoint}deleteuser`;

const get_material_name = `${endPoint}get_material_name`;

const add_material_inward = `${endPoint}add_material_inward`;

const issuing_material = `${endPoint}issuing_material`;

const add_materials = `${endPoint}add_materials`;

const updateuser = `${endPoint}updateuser`;

const get_all_employee_profile = `${endPoint}get_all_employee_profile`;

const get_employee_activities = `${endPoint}get_employee_activities`;

const payslip = `${endPoint}payslip/`;

const transacting_amount = `${endPoint}transacting_amount`;

const get_all_transaction_amount = `${endPoint}get_all_transaction_amount`;

const fetch_expense_category = `${endPoint}fetch_expense_category`;

const getProjectCode = `${endPoint}getProjectCode`;

const check_material_available = `${endPoint}check_material_available`;

// for add chat comments
const add_site_comments = `${endPoint}add_site_comments`;

// for get chat comments
const get_site_comments = `${endPoint}get_site_comments`;

const getCommercialFromSales = `${endPoint}getCommercialFromSales`;

const get_factory_project_items = `${endPoint}get_factory_project_items`;

const get_access_page = `${endPoint}get_access_page`;

const add_access_page = `${endPoint}add_access_page`;

const get_size = `${endPoint}get_size`;

const getAllExpenseStatus = `${endPoint}getAllExpenseStatus`;

const statusUpdateExpense = `${endPoint}statusUpdateExpense`;

const insertReminder = `${endPoint}insertReminder`;

const getAllRejectExpenseSummary = `${endPoint}getAllRejectExpenseSummary`;

const insertReminderEmi = `${endPoint}insertReminderEmi`;

const GetAllReminder = `${endPoint}GetAllReminder`;

const Reminderdropdown = `${endPoint}Reminderdropdown`;

const getAccountDetails = `${endPoint}getAccountDetails`;

const insertAccountDetails = `${endPoint}insertAccountDetails`;

const updateAccountDetails = `${endPoint}updateAccountDetails`;

const deleteAccountDetails = `${endPoint}deleteAccountDetails`;

const insertYearlyCalendar = `${endPoint}insertYearlyCalendar`;

const getYearlyCalendar = `${endPoint}getYearlyCalendar`;

const getmaterial = `${endPoint}getmaterial`;

const deleteYearlyCalendar = `${endPoint}deleteYearlyCalendar`;

const fetch_sub1_category = `${endPoint}fetch_sub1_category`;

const fetch_sub2_category = `${endPoint}fetch_sub2_category`;

const fetch_sub3_category = `${endPoint}fetch_sub3_category`;

const fetch_sub4_category = `${endPoint}fetch_sub4_category`;

const fetch_sub5_category = `${endPoint}fetch_sub5_category`;

const fetch_sub6_category = `${endPoint}fetch_sub6_category`;

const insertFuelExpense = `${endPoint}insertFuelExpense`;

const insertGeneralExpense = `${endPoint}insertGeneralExpense`;

const insertRentedExpense = `${endPoint}insertRentedExpense`;

const insertTravelExpense = `${endPoint}insertTravelExpense`;

const insertTransportExpense = `${endPoint}insertTransportExpense`;

const insertAccommodationExpense = `${endPoint}insertAccommodationExpense`;

const insertFoodExpense = `${endPoint}insertFoodExpense`;

const getCommercialFromTechnical = `${endPoint}getCommercialFromTechnical`;

const insertRepairsMaintenanceExpense = `${endPoint}insertRepairsMaintenanceExpense`;

const insertWagesExpense = `${endPoint}insertWagesExpense`;

const insertOtherExpense = `${endPoint}insertOtherExpense`;

const insertUtilizationExpense = `${endPoint}insertUtilizationExpense`;

const getCity = `${endPoint}getCity`;

const insertPurchase = `${endPoint}insertPurchase`;

const getTechnicalFromCommercial = `${endPoint}getTechnicalFromCommercial`;

const getGroupsDropdown = `${endPoint}getGroupsDropdown`;

const getLedgerCategory1Dropdown = `${endPoint}getLedgerCategory1Dropdown`;

const getLedgerCategory2Dropdown = `${endPoint}getLedgerCategory2Dropdown`;

const getLedgerCategory3Dropdown = `${endPoint}getLedgerCategory3Dropdown`;

const getLedgerCategory4Dropdown = `${endPoint}getLedgerCategory4Dropdown`;

const getLedgerCategory5Dropdown = `${endPoint}getLedgerCategory5Dropdown`;

const insertLedgerCreation = `${endPoint}insertLedgerCreation`;

const getBankDropdown = `${endPoint}getBankDropdown`;

const AddBankBalance = `${endPoint}AddBankBalance`;

const getBalanceDetails = `${endPoint}getBalanceDetails`;

const insertCashInHand = `${endPoint}insertCashInHand`;

const getOfficeDropdown = `${endPoint}getOfficeDropdown`;

const getCashInHandBalanceAmount = `${endPoint}getCashInHandBalanceAmount`;

const getCashInHandExpense = `${endPoint}getCashInHandExpense`;

const getOfficeBalance = `${endPoint}getOfficeBalance`;

const insertOfficeExpense = `${endPoint}insertOfficeExpense`;

const getOfficeExpense = `${endPoint}getOfficeExpense`;

const getApprovalOfficeExpense = `${endPoint}getApprovalOfficeExpense`;

const updateApprovalOfficeExpense = `${endPoint}updateApprovalOfficeExpense`;

const insertTrancationDetails = `${endPoint}insertTrancationDetails`;

const getBankTrancationDetails = `${endPoint}getBankTrancationDetails`;

const getBankBalanceDetails = `${endPoint}getBankBalanceDetails`;

const getLedgerNameDropdown = `${endPoint}getLedgerNameDropdown`;

const getAllLedgers = `${endPoint}getAllLedgers`;

const getLedgerCreation = `${endPoint}getLedgerCreation`;

const updateLedgerCreation = `${endPoint}updateLedgerCreation`;

const updateBankTrancationDetails = `${endPoint}updateBankTrancationDetails`;

const getProjectManagerFromCommercial = `${endPoint}getProjectManagerFromCommercial`;

const getCommercialFromProjectManager = `${endPoint}getCommercialFromProjectManager`;

const updateCommercialToTechnical = `${endPoint}updateCommercialToTechnical`;

const getVendorDropdown = `${endPoint}getVendorDropdown`;

const updateTechnicalToCommercial = `${endPoint}updateTechnicalToCommercial`;

const getUnderMaintenance = `${endPoint}getUnderMaintenance`;

const updateUnderMaintenance = `${endPoint}updateUnderMaintenance`;

const getAllMaintenance = `${endPoint}getAllMaintenance`;

const underInsertMaintenance = `${endPoint}underInsertMaintenance`;

const add_sub1_category = `${endPoint}add_sub1_category`;

const add_sub2_category = `${endPoint}add_sub2_category`;

const add_sub3_category = `${endPoint}add_sub3_category`;

const add_sub4_category = `${endPoint}add_sub4_category`;

const add_sub5_category = `${endPoint}add_sub5_category`;

const getUnderMaintenanceId = `${endPoint}getUnderMaintenanceId`;

const getAllTransportCustomerName = `${endPoint}getAllTransportCustomerName`;

const addTransportCustomerName = `${endPoint}addTransportCustomerName`;

const getAllOwner = `${endPoint}getAllOwner`;

const addOwner = `${endPoint}addOwner`;

const getAllPurchaseMaterialName = `${endPoint}getAllPurchaseMaterialName`;

const addPurchaseMaterialName = `${endPoint}addPurchaseMaterialName`;

const getAllPurchaseMaterialUnit = `${endPoint}getAllPurchaseMaterialUnit`;

const addPurchaseMaterialUnit = `${endPoint}addPurchaseMaterialUnit`;

const getOnDutyApprovel = `${endPoint}getOnDutyApprovel`;

const updateOndutyDetails = `${endPoint}updateOndutyDetails`;

const getOnDutyApproved = `${endPoint}getOnDutyApproved`;

const getOnDutyRejected = `${endPoint}getOnDutyRejected`;

const create_general_code = `${endPoint}create_general_code`;

const getGeneralClientDropdown = `${endPoint}getGeneralClientDropdown`;

const createGeneralCodeClient = `${endPoint}createGeneralCodeClient`;

const addform16 = `${endPoint}addform16`;

const get_form16 = `${endPoint}get_form16`;

const addSiteUnitDropdown = `${endPoint}addSiteUnitDropdown`;

const getSiteUnitDropdown = `${endPoint}getSiteUnitDropdown`;

const addSiteDescriptionDropdown = `${endPoint}addSiteDescriptionDropdown`;

const getSiteDescriptionDropdown = `${endPoint}getSiteDescriptionDropdown`;

const getPriority1Task = `${endPoint}getPriority1Task`;

const getPriority2Task = `${endPoint}getPriority2Task`;

const getPriority3Task = `${endPoint}getPriority3Task`;

const startedTask = `${endPoint}startedTask`;

const endedTask = `${endPoint}endedTask`;

const getOverDueTask = `${endPoint}getOverDueTask`;

const siteProjectsTender = `${endPoint}siteProjectsTender`;

const getDashboardExpense = `${endPoint}getDashboardExpense`;

const getTenderSiteProject = `${endPoint}getTenderSiteProject`;

const getSuggestionBox = `${endPoint}getSuggestionBox`;

const addSuggestionBox = `${endPoint}addSuggestionBox`;

const getEmployeeProjectCode = `${endPoint}getEmployeeProjectCode`;

const addSiteProjectEmployee = `${endPoint}addSiteProjectEmployee`;

const getSiteProjectEmployee = `${endPoint}getSiteProjectEmployee`;

const getSiteProjectDeduction = `${endPoint}getSiteProjectDeduction`;

const createDynamicDropdown = `${endPoint}createDynamicDropdown`;

const getDynamicDropdown = `${endPoint}getDynamicDropdown`;

const getDesignationDynamic = `${endPoint}getDesignationDynamic`;

const getRoleDynamicDropdown = `${endPoint}getRoleDynamicDropdown`;

const createDesignationDynamic = `${endPoint}createDesignationDynamic`;

const createRoleDynamic = `${endPoint}createRoleDynamic`;

const add_department = `${endPoint}add_department`;

const getGraphDashboardExpense = `${endPoint}getGraphDashboardExpense`;

const get_Employees_available_balance = `${endPoint}get_Employees_available_balance`;

const get_project_available_balance = `${endPoint}get_project_available_balance`;

const balanceStatusUpdateExpense = `${endPoint}balanceStatusUpdateExpense`

export {
  balanceStatusUpdateExpense,
  get_project_available_balance,
get_Employees_available_balance,
  methodGet,
  updateBankTrancationDetails,
  getDesignationDynamic,
  getRoleDynamicDropdown,
  createDesignationDynamic,
  createRoleDynamic,
  add_department,
  getGraphDashboardExpense,
  bankimages,
  insertTrancationDetails,
  getLedgerCreation,
  updateLedgerCreation,
  getLedgerNameDropdown,
  getBankBalanceDetails,
  getAllLedgers,
  getBankTrancationDetails,
  getApprovalOfficeExpense,
  updateApprovalOfficeExpense,
  getOfficeDropdown,
  getOfficeExpense,
  insertOfficeExpense,
  getOfficeBalance,
  getCashInHandExpense,
  insertReminderEmi,
  getCashInHandBalanceAmount,
  insertCashInHand,
  getGroupsDropdown,
  getBalanceDetails,
  AddBankBalance,
  getBankDropdown,
  getLedgerCategory5Dropdown,
  insertLedgerCreation,
  getLedgerCategory1Dropdown,
  getLedgerCategory2Dropdown,
  getLedgerCategory3Dropdown,
  getLedgerCategory4Dropdown,
  insertFuelExpense,
  fetch_sub1_category,
  fetch_sub2_category,
  fetch_sub3_category,
  fetch_sub4_category,
  fetch_sub5_category,
  fetch_sub6_category,
  deleteYearlyCalendar,
  getYearlyCalendar,
  getmaterial,
  deleteAccountDetails,
  insertYearlyCalendar,
  getAccountDetails,
  updateAccountDetails,
  insertAccountDetails,
  GetAllReminder,
  get_factory_project_items,
  getAllRejectExpenseSummary,
  insertReminder,
  statusUpdateExpense,
  getAllExpenseStatus,
  get_size,
  get_access_page,
  add_access_page,
  deleteuser,
  getProjectCode,
  fetch_expense_category,
  get_summary_Report,
  fundRequest,
  get_all_transaction_amount,
  transacting_amount,
  payslip,
  get_all_employee_profile,
  get_employee_activities,
  updateuser,
  add_materials,
  issuing_material,
  add_material_inward,
  get_material_name,
  expenseDocs,
  get_factory_project,
  get_project_for_store,
  add_purchase_quotation,
  get_project_for_purchase,
  add_raw_material_availability,
  get_employee_pay_slip,
  get_project_manager,
  add_amount,
  addincentives,
  get_available_balance,
  siteClient,
  get_all_ctc,
  getFlowOfBill,
  reject_notify,
  get_employee_name,
  add_incentives,
  get_expense_summary,
  add_deductions,
  add_employee_salary,
  getAllEmployeeName,
  getParticularTask,
  update_task_details,
  insertTask,
  getTask,
  get_employee_id,
  escalation_approval,
  get_all_escalation,
  get_employee_detail,
  get_department,
  add_employee_details,
  signup,
  get_all_view_leaves,
  reject_leave,
  get_all_food_expense,
  get_all_pending_leaves,
  approve_leave,
  get_all_utilization_expense,
  get_all_others_expense,
  get_all_purchases_expense,
  get_all_wages_expense,
  get_all_general_expense,
  get_all_maintenance_expense,
  get_all_transport_expense,
  get_all_travel_expense,
  get_all_rental_expense,
  get_all_accommodation_expense,
  getDeduction,
  get_all_fuel_expense,
  update_tender_details,
  getParticularSiteProject,
  updateSiteProject,
  create_project_code,
  siteState,
  getAllSiteProject,
  factorydepartment,
  siteDistrict,
  methodPost,
  get_user_leave,
  getall_applied_leave,
  apply_leave,
  update_factory_project,
  login,
  get_factory_employees,
  get_all_factory_project,
  add_factory_project,
  getorderdetails,
  getproductdetails,
  endPoint,
  addproduct,
  getsingleproject,
  editproduct,
  getproduct,
  updateassignedproject,
  assignproject,
  addvendor,
  procurement,
  getassignedorders,
  deleteproduct,
  addexpense,
  deleteexpense,
  getallexpense,
  updateexpense,
  getexpense,
  add_announcement,
  getallannouncement,
  getAllFundRequest,
  getPendingFundRequest,
  approve_notify,
  FactoryDocs,
  ProfileDocs,
  SiteDocs,
  QuoteDocs,
  check_material_available,
  add_site_comments,
  get_site_comments,
  reminder,
  Reminderdropdown,
  insertTravelExpense,
  insertTransportExpense,
  insertRentedExpense,
  insertAccommodationExpense,
  insertFoodExpense,
  insertRepairsMaintenanceExpense,
  insertWagesExpense,
  insertOtherExpense,
  insertGeneralExpense,
  insertUtilizationExpense,
  getCity,
  insertPurchase,
  getCommercialFromSales,
  getTechnicalFromCommercial,
  getCommercialFromTechnical,
  getProjectManagerFromCommercial,
  getCommercialFromProjectManager,
  updateCommercialToTechnical,
  getVendorDropdown,
  updateTechnicalToCommercial,
  getUnderMaintenance,
  updateUnderMaintenance,
  getAllMaintenance,
  underInsertMaintenance,
  add_sub1_category,
  add_sub2_category,
  add_sub3_category,
  add_sub4_category,
  add_sub5_category,
  getUnderMaintenanceId,
  getAllTransportCustomerName,
  addTransportCustomerName,
  addOwner,
  getAllOwner,
  getAllPurchaseMaterialName,
  addPurchaseMaterialName,
  getAllPurchaseMaterialUnit,
  addPurchaseMaterialUnit,
  getOnDutyApprovel,
  updateOndutyDetails,
  getOnDutyApproved,
  getOnDutyRejected,
  create_general_code,
  getGeneralClientDropdown,
  createGeneralCodeClient,
  addform16,
  get_form16,
  addSiteUnitDropdown,
  getSiteUnitDropdown,
  addSiteDescriptionDropdown,
  getSiteDescriptionDropdown,
  getPriority1Task,
  getPriority2Task,
  getPriority3Task,
  startedTask,
  endedTask,
  getOverDueTask,
  siteProjectsTender,
  getDashboardExpense,
  getTenderSiteProject,
  getSuggestionBox,
  addSuggestionBox,
  getEmployeeProjectCode,
  addSiteProjectEmployee,
  getSiteProjectEmployee,
  getSiteProjectDeduction,
  createDynamicDropdown,
  getDynamicDropdown,
};
