import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/colors/teal.css";
import "react-multi-date-picker/styles/colors/red.css";
import { getYearlyCalendar } from "../../../API_Service/API_Links";
import axios from "axios";
import { useEffect } from "react";

export default function AnnualCalender() {
  const [values, setValues] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <TextField
        onFocus={onFocus}
        size="small"
        value={value}
        onChange={onChange}
        label="Create Holiday"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  useEffect(() => {
    axios({
      method: "GET",
      url: getYearlyCalendar,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setHolidays(res.data.data.map((i) => i.LeaveDate));
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  return (
    <Box p={2}>
      <Box p={2} display="flex" justifyContent="center">
        <Calendar
          className="teal custom-calendar"
          fullYear
          numberOfMonths={2}
          mapDays={({ date }) => {
            let props = {};
            const isHoliday = holidays.includes(date.format("YYYY-MM-DD"));
            if (isHoliday) {
              props.className = "highlight highlight-red bold-text";
            }
            return props;
          }}
        />
      </Box>
    </Box>
  );
}
