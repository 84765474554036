import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/colors/teal.css";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import axios from "axios";
import {
  deleteYearlyCalendar,
  getYearlyCalendar,
  insertYearlyCalendar,
  methodPost,
} from "../../../API_Service/API_Links";
import { useEffect } from "react";
import SnackBar from "../../SnackBar";

export default function UpdateCalender() {
  const [values, setValues] = useState([]);
  const [values1, setValues1] = useState();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <TextField
        onFocus={onFocus}
        size="small"
        value={value}
        onChange={onChange}
        label="Add Holiday"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  function CustomInput1({ onFocus, value, onChange }) {
    return (
      <TextField
        onFocus={onFocus}
        size="small"
        value={value}
        onChange={onChange}
        label="Remove Holiday"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  const AddNew = () => {
    const formattedDates = values.map((date) => {
      const i = date.toDate();
      const year = i.getFullYear();
      const month = String(i.getMonth() + 1).padStart(2, "0");
      const day = String(i.getDate()).padStart(2, "0");

      const obj = {
        Date: `${year}-${month}-${day}`,
      };
      return obj;
    });
    const sendData = new FormData();
    sendData.append("dateList", JSON.stringify(formattedDates));

    axios({
      method: methodPost,
      url: insertYearlyCalendar,
      data: sendData,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setValues([]);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const RemoveDate = () => {
    const dateStr = values1; // Replace with the actual date string
    const date = new Date(dateStr);

    if (!isNaN(date.getTime())) {
      // Check if date is valid
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const sendData = new FormData();
      sendData.append("Date", formattedDate);

      axios({
        method: methodPost,
        url: deleteYearlyCalendar,
        data: sendData,
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setValues1();
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  return (
    <Box p={2}>
      <Box p={4} component={Card}>
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Add New Holiday</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
              >
                <DatePicker
                  multiple
                  value={values}
                  onChange={setValues}
                  calendarPosition={"right-center"}
                  fixMainPosition={true}
                  sort
                  highlightToday
                  showOtherDays={false}
                  hideOnScroll
                  weekends={true}
                  offsetX={-20}
                  className="teal"
                  render={<CustomInput />}
                  dateSeparator=" & "
                  plugins={[<DatePanel />, [weekends()]]}
                />
                <Button variant="contained" onClick={AddNew}>
                  New Holiday
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Remove Holiday</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
              >
                <DatePicker
                  value={values1}
                  onChange={setValues1}
                  calendarPosition={"right-center"}
                  fixMainPosition={true}
                  sort
                  highlightToday
                  showOtherDays={false}
                  hideOnScroll
                  weekends={true}
                  offsetX={-20}
                  className="teal"
                  render={<CustomInput1 />}
                />

                <Button variant="contained" onClick={RemoveDate}>
                  Remove Holiday
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
