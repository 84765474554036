import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Autocomplete,
  Stack,
} from "@mui/material";
import axios from "axios";
import {
  add_material_inward,
  add_materials,
  get_material_name,
  methodPost,
} from "../../API_Service/API_Links";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { appendData } from "../../Variables/Variables";
import SnackBar from "../SnackBar";
import Heading from "../Heading";

export default function MaterialInwards() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState(null);
  const [selectedMaterialsQty, setSelectedMaterialsQty] = useState("");
  const [StoredMaterialsData, setStoredMaterialsData] = useState([]);
  const [PostStoredMaterials, setPostStoredMaterialsData] = useState([]);

  const [GENumber, setGENumber] = useState("");
  const [GRNNumber, setGRNNumber] = useState("");
  const [InvoiceNumber, setInvoiceNumber] = useState("");

  const [newMaterialName, setNewMaterialName] = useState("");
  const [newMaterialCode, setNewMaterialCode] = useState("");
  const [newMaterialUnit, setNewMaterialUnit] = useState("");

  const getAllMaterial = () => {
    axios({
      method: "GET",
      url: get_material_name,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setOptions(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  useEffect(() => {
    getAllMaterial();
  }, []);

  const storeMaterial = () => {
    const newObject = {
      MaterialName: selectedMaterials.MaterialName,
      InwardQty: selectedMaterialsQty,
    };
    setStoredMaterialsData([...StoredMaterialsData, newObject]);
    const newObject1 = {
      MaterialId: selectedMaterials.MaterialId,
      InwardQty: selectedMaterialsQty,
      MaterialUnit: selectedMaterials.MaterialUnit,
    };
    setPostStoredMaterialsData([...PostStoredMaterials, newObject1]);
    setSelectedMaterials(null);
    setSelectedMaterialsQty("");
  };

  const CreateNewMaterial = () => {
    const serverData = {
      MaterialName: newMaterialName,
      MaterialCode: newMaterialCode,
      MaterialUnit: newMaterialUnit,
    };
    const sendData = appendData(serverData);
    axios({
      method: methodPost,
      url: add_materials,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setNewMaterialCode("");
          setNewMaterialName("");
          setNewMaterialUnit("");
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const handleSubmit = () => {
    const serverData = {
      GENumber: GENumber,
      GRNNumber: GRNNumber,
      InvoiceNumber: InvoiceNumber,
      materials: JSON.stringify(PostStoredMaterials),
    };
    const sendData = appendData(serverData);
    axios({
      method: methodPost,
      url: add_material_inward,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setGENumber("");
          setGRNNumber("");
          setInvoiceNumber("");
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  return (
    <Box p={2}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

      <Box component={Card} p={4} className="cardBoxshadow">
        <Container>
          <Box
            component={Card}
            p={3}
            my={2}
            border="1px solid #E0E3DE"
            className="cardBoxshadow"
          >
            <Box p={2}>
              <Grid container spacing={2} justifyContent="start">
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="MaterialName"
                      label="Material Name"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={newMaterialName}
                      onChange={(e) => setNewMaterialName(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="MaterialCode"
                      label="Material Code"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={newMaterialCode}
                      onChange={(e) => setNewMaterialCode(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="MaterialUnit"
                      label="Material Unit"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={newMaterialUnit}
                      onChange={(e) => setNewMaterialUnit(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={CreateNewMaterial}
                      fullWidth
                    >
                      CREATE
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>

        <Container>
          <Box
            component={Card}
            p={3}
            border="1px solid #E0E3DE"
            className="cardBoxshadow"
          >
            <Box p={2}>
              <Grid container spacing={2} justifyContent="start">
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box mb={3}>
                    <Autocomplete
                      id="tags-filled"
                      options={[...options]}
                      value={selectedMaterials}
                      onChange={(event, value) => setSelectedMaterials(value)}
                      getOptionLabel={(option) => option.MaterialName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          label="Select a Material"
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box mb={3}>
                    <TextField
                      id="InwardQty"
                      label="Inward Qty"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={selectedMaterialsQty}
                      onChange={(e) => setSelectedMaterialsQty(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box mb={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={storeMaterial}
                    >
                      <AddCircleOutlineIcon sx={{ verticalAlign: "middle" }} />
                      Add
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Material & Qty
                  </Typography>
                  <Stack spacing={2}>
                    {StoredMaterialsData &&
                      StoredMaterialsData.map((i) => {
                        return (
                          <Box>
                            <Typography variant="body1" fontWeight={600}>
                              {i.MaterialName} - {i.InwardQty}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Stack>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="GE_Number"
                      label="GE Number"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={GENumber}
                      onChange={(e) => setGENumber(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="GRN_Number"
                      label="GRN Number"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={GRNNumber}
                      onChange={(e) => setGRNNumber(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box mb={3}>
                    <TextField
                      id="Invoice Number"
                      label="Invoice Number"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                      value={InvoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
