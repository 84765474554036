import { v1 as uuidv1 } from "uuid";
import ProjectIcon from "../Assets/Icons/icons8-project-color-96.png";
import NewExpenseIcon from "../Assets/Icons/icons8-add-list-96.png";
import AllExpenseIcon from "../Assets/Icons/icons8-overview-96.png";
import AnnounceIcon from "../Assets/Icons/icons8-commercial-color-96.png";
import SupportIcon from "../Assets/Icons/icons8-computer-support-color-96.png";
import FinanceIcon from "../Assets/Icons/icons8-bill-color-96.png";
import HRMSIcon from "../Assets/Icons/icons8-resources-color-96.png";
import BankIcon from "../Assets/Icons/icons8-bank-96.png";
import EventIcon from "../Assets/Icons/icons8-event-96.png";
import RemindIcon from "../Assets/Icons/icons8-alarm-clock-96.png";
import CalendarIcon from "../Assets/Icons/icons8-calendar-96.png";
import TaskIcon from "../Assets/Icons/icons8-task-color-96.png";
import AccessIcon from "../Assets/Icons/icons8-access-96.png";
import DownloadIcon from "@mui/icons-material/Download";
import ApprovalIcon from "../Assets/Icons/icons8-approval-color-96.png";
import { Button, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import LedgerIcon from "../Assets/Icons/icons8-ledger-96.png";
import HandCashIcon from "../Assets/Icons/icons8-cash-in-hand-96.png";
import OfficeExpenseIcon from "../Assets/Icons/icons8-office-96.png";
import AssignmentIcon from "@mui/icons-material/Assignment";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import QuizIcon from "@mui/icons-material/Quiz";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import FactoryIcon from "@mui/icons-material/Factory";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import BalconyIcon from "@mui/icons-material/Balcony";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RuleIcon from "@mui/icons-material/Rule";
import ShopIcon from "@mui/icons-material/Shop";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SummarizeIcon from "@mui/icons-material/Summarize";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ListIcon from "@mui/icons-material/List";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CommitIcon from "@mui/icons-material/Commit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PanToolIcon from "@mui/icons-material/PanTool";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import AddCardIcon from "@mui/icons-material/AddCard";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import MoneyIcon from "@mui/icons-material/Money";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import TableChartIcon from "@mui/icons-material/TableChart";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CompressIcon from "@mui/icons-material/Compress";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import CampaignIcon from "@mui/icons-material/Campaign";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const role = JSON.parse(localStorage.getItem("role"));

const sidebarItems = [
  {
    label: "DashBoard",
    icon: <DashboardIcon sx={{ color: "#064420" }} />,
    link: "dashboard",
  },
  {
    label: "Site Project",
    icon: <Inventory2Icon sx={{ color: "#064420" }} />,
    subItems: [
      {
        label: "New Project",
        link: "siteproject",
        icon: <AddBoxIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Assigned Projects",
        link: "assignedsiteproject",
        icon: <AssignmentIcon sx={{ color: "#00B906" }} />,
      },
    ],
  },
  {
    label: "Factory Project",
    icon: <FactoryIcon sx={{ color: "#064420" }} />,
    subItems: [
      {
        label: "Project Creation",
        link: "factoryproject",
        icon: <AddBoxIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Assigned Projects",
        link: "assignedfactoryproject",
        icon: <AssignmentIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Commercial From Sales",
        link: "commercialfromsales",
        icon: <LocalConvenienceStoreIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Technical From Commercial",
        link: "technicalfromcommercial",
        icon: <BalconyIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Material Inwards",
        link: "materialinwards",
        icon: <DoubleArrowIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Material Issued",
        link: "materialissued",
        icon: <RuleIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Store",
        link: "store",
        icon: <AddHomeWorkIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Purchase",
        link: "purchase",
        icon: <ShopIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Commercial From Technical",
        link: "commercialfromtechnical",
        icon: <BalconyIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "ProjectManager From Commercial",
        link: "projectmanagerfromcommercial",
        icon: <LocalConvenienceStoreIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Commercial From ProjectManager",
        link: "commercialfromprojectmanager",
        icon: <AllInboxIcon sx={{ color: "#00B906" }} />,
      },
    ],
  },
  {
    label: "Finance",
    icon: <StorefrontIcon sx={{ color: "#064420" }} />,
    link: "finance",
    subItems: [
      {
        label: "Expense",
        link: "expense",
        icon: <MonetizationOnIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "New Expense",
            link: "expense/newexpense",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Summary",
            link: "expense/allexpense",
            icon: <SummarizeIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Maintenance",
            link: "expense/maintenance",
            icon: <EngineeringIcon sx={{ color: "#748E63" }} />,
          },
          {
            label:"Employee Expense",
            link:"expense/EmplyeeExpense",
            icon: <SummarizeIcon sx={{ color: "#748E63" }} />,
          }
        ],
      },
      {
        label: "Bank A/C",
        link: "bankac",
        icon: <AccountBalanceIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Transfer",
            link: "transfer",
            icon: <CompareArrowsIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "New Bank Account",
            link: "bank",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "All Bank Accounts",
            link: "allbank",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Add Bank Balance",
            link: "bankbalance",
            icon: <AccountBalanceWalletIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Bank Balance Summary",
            link: "balancesummary",
            icon: <SummarizeIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Credit",
            link: "credit",
            icon: <CreditScoreIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Debit",
            link: "debit",
            icon: <CreditCardIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Ledger",
        link: "ledger",
        icon: <CommitIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Update Ledger",
            link: "ledger",
            icon: <BorderColorIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "View Ledgers",
            link: "viewledger",
            icon: <VisibilityIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Cash In Hand",
        link: "cashinhand",
        icon: <PanToolIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "New Entry",
            link: "cashinhand",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Spend List",
            link: "spendlist",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Office Expense",
        link: "officeexpense",
        icon: <MeetingRoomIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "New Office Expense",
            link: "officeexpense",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "All Office Expenses",
            link: "allofficeexpenses",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Approved Office Expenses",
            link: "approvedofficeexpenses",
            icon: <TaskAltIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Payslip",
        link: "payslip",
        icon: <ReceiptIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Incentives",
        link: "incentives",
        icon: <RequestQuoteIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Create Incentive",
            link: "createincentive",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "All Incentives",
            link: "allincentives",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Payout",
        link: "payout",
        icon: <PriceCheckIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Fund Request",
        link: "fundrequest",
        icon: <AddCardIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Pending Funds",
            link: "fundrequest",
            icon: <SubdirectoryArrowRightIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "View All Funds",
            link: "viewfundrequest",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
    ],
  },
  {
    label: "HRMS",
    icon: <Diversity2Icon sx={{ color: "#064420" }} />,
    link: "hrms",
    subItems: [
      {
        label: "Profile",
        link: "profile",
        icon: <AccountCircleIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Hierarchy",
        link: "hierarchy",
        icon: <LineWeightIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Last 3 Payout",
        link: "last3pay",
        icon: <PriceCheckIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Leaves",
        link: "leaves",
        icon: <TransferWithinAStationIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Approve Leave",
            link: "leaves",
            icon: <TaskAltIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "View Leaves",
            link: "viewleaves",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "CTC",
        link: "ctc",
        icon: <MoneyIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Form16",
        link: "form16",
        icon: <DynamicFormIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "New Form",
            link: "form16",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Table Form 16",
            link: "tableform",
            icon: <TableChartIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "OnBoard",
        link: "onboard",
        icon: <AssignmentIndIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "OnBoard Employee",
            link: "onboard",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Create CTC",
            link: "createctc",
            icon: <MoneyIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Edit Employee",
            link: "editemployee",
            icon: <BorderColorIcon sx={{ color: "#748E63" }} />,
          },

          {
            label: "Delete Employee",
            link: "deleteemployee",
            icon: <DeleteForeverIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Escalation",
        link: "complaints",
        icon: <CompressIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Access",
        link: "access",
        icon: <VpnKeyIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "OnDuty",
        link: "onduty",
        icon: <OnlinePredictionIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Pending",
            link: "pendingod",
            icon: <SubdirectoryArrowRightIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Approved",
            link: "approvedod",
            icon: <TaskAltIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Rejected",
            link: "rejectedod",
            icon: <UnpublishedIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
    ],
  },
  {
    label: "Task",
    icon: <AddTaskIcon sx={{ color: "#064420" }} />,
    link: "task",
    subItems: [
      {
        label: "Assigned Task",
        link: "assignedtask",
        icon: <AssignmentIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Create Task",
        link: "newtask",
        icon: <AddBoxIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "Task 1",
        link: "taskone",
        icon: (
          <Typography variant="caption" component="span" fontSize="10px">
            <AddTaskIcon sx={{ color: "#00B906" }} /> 1
          </Typography>
        ),
      },
      {
        label: "Task 2",
        link: "tasktwo",
        icon: (
          <Typography variant="caption" component="span" fontSize="10px">
            <AddTaskIcon sx={{ color: "#00B906" }} /> 2
          </Typography>
        ),
      },
      {
        label: "Task 3",
        link: "taskthree",
        icon: (
          <Typography variant="caption" component="span" fontSize="10px">
            <AddTaskIcon sx={{ color: "#00B906" }} /> 3
          </Typography>
        ),
      },
      {
        label: "STARTED Task",
        link: "startedtask",
        icon: <AssignmentReturnedIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "ENDED Task",
        link: "endedtask",
        icon: <PlaylistAddCheckIcon sx={{ color: "#00B906" }} />,
      },
      {
        label: "OVER DUE Task",
        link: "odtask",
        icon: <LowPriorityIcon sx={{ color: "#00B906" }} />,
      },
    ],
  },
  {
    label: "Announcement",
    icon: <CampaignIcon sx={{ color: "#064420" }} />,
    link: "announcement",
    subItems: [
      {
        label: "Events",
        link: "events",
        icon: <EventAvailableIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Create Events",
            link: "createevent",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "All Events",
            link: "allevents",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Reminder",
        link: "reminder",
        icon: <AddAlarmIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "EMI",
            link: "emi",
            icon: <AssuredWorkloadIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "General",
            link: "general",
            icon: <Diversity3Icon sx={{ color: "#748E63" }} />,
          },
          {
            label: "View All",
            link: "allreminder",
            icon: <ListIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
      {
        label: "Calendar",
        link: "calendar",
        icon: <DateRangeIcon sx={{ color: "#00B906" }} />,
        subItems: [
          {
            label: "Create Calendar",
            link: "createcalendar",
            icon: <AddBoxIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Annual Calendar",
            link: "annualcalendar",
            icon: <CalendarMonthIcon sx={{ color: "#748E63" }} />,
          },
          {
            label: "Update Calendar",
            link: "updatecalendar",
            icon: <BorderColorIcon sx={{ color: "#748E63" }} />,
          },
        ],
      },
    ],
  },
];

const orderdetails = [
  {
    id: uuidv1(),
    name: "Order Number",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Customer Name",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Mobile Number",
  },
  {
    id: uuidv1(),
    name: "Services",
  },
  {
    id: uuidv1(),
    name: "Company Name",
  },
  {
    id: uuidv1(),
    name: "Project Name",
  },
  {
    id: uuidv1(),
    name: "Project Description",
  },
  {
    id: uuidv1(),
    name: "Expected Date",
  },
  {
    id: uuidv1(),
    name: "Project Budget",
  },
  {
    id: uuidv1(),
    name: "Project Start Date",
  },
  {
    id: uuidv1(),
    name: "Project Status",
  },
];

const emporderdetails = [
  {
    id: uuidv1(),
    name: "Order Number",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Assigned Date",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Due Date",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Project Status",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Remarks",
  },
];

const expensehead = [
  {
    id: uuidv1(),
    name: "Employee Name",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Expense Type",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Date",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Amount",
  },
  {
    id: uuidv1(),
    name: "Edit",
  },
  {
    id: uuidv1(),
    name: "Delete",
  },
];
const orderstatus = [
  {
    id: uuidv1(),
    name: "Date",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Product Quantity",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Remarks",
  },
];

const procuredetails = [
  {
    id: uuidv1(),
    name: " Name",
    // filter: Boolean(true)
  },
  {
    id: uuidv1(),
    name: "GST Number",
    // filter: Boolean(true)
  },
  {
    id: uuidv1(),
    name: "Project Cost",
  },
  {
    id: uuidv1(),
    name: "Address",
    // filter: Boolean(true)
  },
  {
    id: uuidv1(),
    name: "Lead Time",
    // filter: Boolean(true)
  },
  {
    id: uuidv1(),
    name: "Documents",
    // filter: Boolean(true)
  },
];

const procureorder = [
  {
    id: uuidv1(),
    name: "Status Assigned",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Date",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Due Date",
  },
  {
    id: uuidv1(),
    name: "Status",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Remarks",
    filter: Boolean(true),
  },
];
const productdetails = [
  {
    id: uuidv1(),
    name: "Product Name",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Produst Description",
  },
  {
    id: uuidv1(),
    name: "Product Photos",
  },
  {
    id: uuidv1(),
    name: "Product Quantity",
  },
  {
    id: uuidv1(),
    name: "Product Quality",
  },
  {
    id: uuidv1(),
    name: "Product Stock",
    filter: Boolean(true),
  },
  {
    id: uuidv1(),
    name: "Product Price",
  },
  {
    id: uuidv1(),
    name: "Discount Price",
  },
  {
    id: uuidv1(),
    name: "Edit",
  },
  {
    id: uuidv1(),
    name: "Delete",
  },
];

const Homepagecardsinfo = [
  {
    Icon: FinanceIcon,
    name: "Dash Board",
    path: "dashboard",
    access: "SiteProject",
  },
  {
    Icon: ProjectIcon,
    name: "Site Project",
    path: "siteproject",
    access: "SiteProject",
  },
  {
    Icon: ProjectIcon,
    name: "Factory Project",
    path: "project",
    access: "FactoryProject",
  },
  {
    Icon: FinanceIcon,
    name: "Finance",
    path: "finance",
    access: "Finance",
  },
  {
    Icon: HRMSIcon,
    name: "HRMS",
    path: "HRMS",
    access: "HRMS",
  },
  {
    Icon: TaskIcon,
    name: "Task",
    path: "task",
    access: "Task",
  },
  {
    Icon: AnnounceIcon,
    name: "Announcement",
    path: "announcement",
    access: "Announcement",
  },
];

const financepageControllerView = [
  {
    Icon: HRMSIcon,
    name: "Expense",
    path: "expense",
    access: "Expense",
  },
  {
    Icon: BankIcon,
    name: "Bank A/C",
    path: "bank",
    access: "Bank",
  },
  {
    Icon: LedgerIcon,
    name: "Ledger",
    path: "ledger",
    access: "Ledger",
  },
  {
    Icon: HandCashIcon,
    name: "Cash In Hand",
    path: "cashonhand",
    access: "CashInHand",
  },
  {
    Icon: OfficeExpenseIcon,
    name: "Office Expense",
    path: "officeexpense",
    access: "OfficeExpense",
  },
  {
    Icon: ProjectIcon,
    name: "Payslip",
    path: "payslip",
    access: "PaySlip",
  },
  {
    Icon: FinanceIcon,
    name: "Incentives",
    path: "incentives",
    access: "Incentives",
  },
  {
    Icon: HRMSIcon,
    name: "Payout",
    path: "payout",
    access: "PayOut",
  },
  {
    Icon: HRMSIcon,
    name: "Fund Request",
    path: "fund_request",
    access: "FundRequest",
  },
];

const AnnouncementView = [
  {
    Icon: EventIcon,
    name: "Events",
    path: "events",
    access: "SeeAllEvents",
  },
  {
    Icon: RemindIcon,
    name: "Reminder",
    path: "reminder",
    access: "AllReminders",
  },
  {
    Icon: CalendarIcon,
    name: "Calendar",
    path: "calendar",
    access: "AnnualCalendar",
  },
];

const ExpenseView = [
  {
    Icon: NewExpenseIcon,
    name: "New Expense",
    path: "newexpense",
    access: "Expense",
  },
  {
    Icon: AllExpenseIcon,
    name: "Summary",
    path: "allexpense",
    access: "Expense",
  },
  {
    Icon: AllExpenseIcon,
    name: "Maintenance",
    path: "maintenance",
    access: "Expense",
  },
];

const financepageEmployeeView = [
  {
    Icon: ProjectIcon,
    name: "Payslip",
    path: "payslip",
    access: "PaySlip",
  },
  {
    Icon: FinanceIcon,
    name: "Incentives",
    path: "incentives",
    access: "Incentives",
  },
  {
    Icon: HRMSIcon,
    name: "Payout",
    path: "payout",
    access: "PayOut",
  },
];

const expenseTable = [
  {
    Icon: ProjectIcon,
    name: "Payslip",
    path: "payslip",
  },
  {
    Icon: FinanceIcon,
    name: "Incentives",
    path: "incentives",
  },
  {
    Icon: HRMSIcon,
    name: "Payout",
    path: "payout",
  },
];

const HRMSControllerView = [
  {
    Icon: HRMSIcon,
    name: "Profile",
    path: "profile",
    access: "Profile",
  },
  {
    Icon: TaskIcon,
    name: "Hierarchy",
    path: "hierarchy",
    access: "Hierarchy",
  },
  {
    Icon: SupportIcon,
    name: "Last 3 Payout",
    path: "last3pay",
    access: "Last3Payout",
  },
  {
    Icon: ApprovalIcon,
    name: "Leaves",
    path: "leaves",
    access: "Leaves",
  },
  {
    Icon: TaskIcon,
    name: "CTC",
    path: "CTC",
    access: "CTC",
  },
  {
    Icon: AnnounceIcon,
    name: "Form16",
    path: "form16",
    access: "Form16",
  },
  {
    Icon: AnnounceIcon,
    name: "OnBoard",
    path: "onboard",
    access: "OnBoard",
  },
  {
    Icon: SupportIcon,
    name: "Escalation",
    path: "complaints",
    access: "Compliants",
  },
  {
    Icon: AccessIcon,
    name: "Access",
    path: "access",
    access: "Compliants",
  },
  {
    Icon: AnnounceIcon,
    name: "OnDuty",
    path: "onduty",
    access: "Compliants",
  },
];

const LeaveApprovaltable = [
  {
    name: "Ajay",
    id: "2901",
    appliedon: "02-02-2023",
    appliedfor: "01-02-2023",
    status: "pending",
    approve: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        Approve
      </Button>
    ),
    reject: (
      <Button sx={{ bgcolor: "red", ":hover": { bgcolor: "#616e80" } }}>
        Reject
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    appliedon: "02-02-2023",
    appliedfor: "01-02-2023",
    status: "pending",
    approve: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        Approve
      </Button>
    ),
    reject: (
      <Button sx={{ bgcolor: "red", ":hover": { bgcolor: "#616e80" } }}>
        Reject
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    appliedon: "02-02-2023",
    appliedfor: "01-02-2023",
    status: "pending",
    approve: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        Approve
      </Button>
    ),
    reject: (
      <Button sx={{ bgcolor: "red", ":hover": { bgcolor: "#616e80" } }}>
        Reject
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    appliedon: "02-02-2023",
    appliedfor: "01-02-2023",
    status: "pending",
    approve: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        Approve
      </Button>
    ),
    reject: (
      <Button sx={{ bgcolor: "red", ":hover": { bgcolor: "#616e80" } }}>
        Reject
      </Button>
    ),
  },
];

const allProfileTable = [
  {
    name: "Ajay",
    id: "2901",
    join: "02-02-2023",
    status: "Active",
    view: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        View
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    join: "02-02-2023",
    status: "Active",
    view: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        View
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    join: "02-02-2023",
    status: "Active",
    view: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        View
      </Button>
    ),
  },
  {
    name: "Ajay",
    id: "2901",
    join: "02-02-2023",
    status: "Active",
    view: (
      <Button sx={{ bgcolor: "#7bc54c", ":hover": { bgcolor: "#616e80" } }}>
        View
      </Button>
    ),
  },
];
const allComplaintsTable = [
  {
    id: "2901",
    complaint: "Desktop BSOD'ed",
    raised: 2901,
    raisedon: "01-02-2023",
    level: "HIGH",
    status: "completed",
    response: "New screen Provided",
  },
  {
    id: "2901",
    complaint: "Desktop BSOD'ed",
    raised: 2901,
    raisedon: "01-02-2023",
    level: "LOW",
    status: "Pending",
    response: "New screen Provided",
  },
  {
    id: "2901",
    complaint: "Desktop BSOD'ed",
    raised: 2901,
    raisedon: "01-02-2023",
    level: "Medium",
    status: "completed",
    response: "New screen Provided",
  },
  {
    id: "2901",
    complaint: "Desktop BSOD'ed",
    raised: 2901,
    raisedon: "01-02-2023",
    level: "Medium",
    status: "completed",
    response: "New screen Provided",
  },
];

const OnBoardtable = [
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
];

const OnBoardDeletetable = [
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    delete: <DeleteIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    delete: <DeleteIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    delete: <DeleteIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    delete: <DeleteIcon />,
  },
  {
    id: "2901",
    fname: "Ajay",
    lname: "kumar",
    joined: "01-02-2023",
    status: "Active",
    delete: <DeleteIcon />,
  },
];

const paysliptable = [
  {
    name: "Ajay",
    id: "2901",
    period: "Feb-2023",
    file: "File",
    Download: <DownloadIcon />,
  },
  {
    name: "VJay",
    id: "4901",
    period: "Feb-2023",
    file: "File",
    Download: <DownloadIcon />,
  },
  {
    name: "Ajax",
    id: "2961",
    period: "Feb-2023",
    file: "File",
    Download: <DownloadIcon />,
  },
  {
    name: "Shell",
    id: "2921",
    period: "Feb-2023",
    file: "File",
    Download: <DownloadIcon />,
  },
];

const incentivetable = [
  {
    name: "Ajay",
    id: "2901",
    period: "Feb-2023",
    actual: 2500,
    paid: 2000,
  },
  {
    name: "Ajay",
    id: "2901",
    period: "Feb-2023",
    actual: 2500,
    paid: 1000,
  },
  {
    name: "Ajay",
    id: "2901",
    period: "Feb-2023",
    actual: 2500,
    paid: 800,
  },
  {
    name: "Ajay",
    id: "2901",
    period: "Feb-2023",
    actual: 2500,
    paid: 1700,
  },
];

const ctctable = [
  {
    name: "Ajay",
    id: "2901",
    join: "Feb-2023",
    ctc: 250000,
    pf: 2000,
    gratuity: 1000,
    ins: 800,
    tax: 200,
  },
  {
    name: "Ajay",
    id: "2901",
    join: "Feb-2023",
    ctc: 250000,
    pf: 2000,
    gratuity: 1000,
    ins: 800,
    tax: 200,
  },
  {
    name: "Ajay",
    id: "2901",
    join: "Feb-2023",
    ctc: 250000,
    pf: 2000,
    gratuity: 1000,
    ins: 800,
    tax: 200,
  },
  {
    name: "Ajay",
    id: "2901",
    join: "Feb-2023",
    ctc: 250000,
    pf: 2000,
    gratuity: 1000,
    ins: 800,
    tax: 200,
  },
];

const siteprojectTable = [
  {
    name: "EB-03-SUB-NB/JAN/21/RADIAL ROAD",
    date: "01-12-2023 11:11",
    team: "finance",
    status: "In Progress",
    last: "AK(11-01-2023)",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "EB-02-SUB-NB/JAN/21/RADIAL ROAD",
    date: "01-12-2023 11:11",
    team: "operations",
    status: "In Progress",
    last: "TK(11-01-2023)",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "EB-03-SUB-NB/JAN/21/RADIAL ROAD",
    date: "01-12-2023 11:11",
    team: "finance",
    status: "In Progress",
    last: "VK(11-01-2023)",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "EB-04-SUB-NB/JAN/21/RADIAL ROAD",
    date: "01-12-2023 11:11",
    team: "operations",
    status: "In Progress",
    last: "AK(11-01-2023)",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "EB-03-SUB-NB/JAN/21/RADIAL ROAD",
    date: "01-12-2023 11:11",
    team: "finance",
    status: "In Progress",
    last: "AK(11-01-2023)",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
];

// Admin View End

// EMployee View Start

const HRMSemployeeView = [
  {
    Icon: HRMSIcon,
    name: "Profile",
    path: "profile",
  },
  {
    Icon: SupportIcon,
    name: "Last 3 Payout",
    path: "last3pay",
  },
  {
    Icon: ApprovalIcon,
    name: "Leaves",
    path: "leaves",
  },
  {
    Icon: AnnounceIcon,
    name: "Form16",
    path: "form16",
  },
  {
    Icon: SupportIcon,
    name: "Complaints",
    path: "complaints",
  },
];

const assignedtaskEmpTable = [
  {
    name: "Project1",
    code: "1123",
    members: "Arjun, Vignesh, Vikki",
    deadline: "01-05-2023",
    type: "Project",
    status: "In Progress",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "Project1",
    code: "1123",
    members: "Arjun, Vignesh, Vikki",
    deadline: "01-05-2023",
    type: "Non-Project",
    status: "In Progress",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "Project1",
    code: "1123",
    members: "Arjun, Vignesh, Vikki",
    deadline: "01-05-2023",
    type: "Project",
    status: "In Progress",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "Project1",
    code: "1123",
    members: "Arjun, Vignesh, Vikki",
    deadline: "01-05-2023",
    type: "Non-Project",
    status: "In Progress",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
  {
    name: "Project1",
    code: "1123",
    members: "Arjun, Vignesh, Vikki",
    deadline: "01-05-2023",
    type: "Project",
    status: "In Progress",
    view: <VisibilityIcon />,
    edit: <ModeEditIcon />,
  },
];

const appendData = (data) => {
  const serverData = new FormData();
  for (var key in data) {
    serverData.append(key, data[key]);
  }
  return serverData;
};

export {
  appendData,
  sidebarItems,
  assignedtaskEmpTable,
  AnnouncementView,
  ExpenseView,
  HRMSemployeeView,
  financepageEmployeeView,
  orderdetails,
  siteprojectTable,
  OnBoardDeletetable,
  OnBoardtable,
  allComplaintsTable,
  allProfileTable,
  LeaveApprovaltable,
  ctctable,
  HRMSControllerView,
  paysliptable,
  incentivetable,
  Homepagecardsinfo,
  financepageControllerView,
  productdetails,
  procureorder,
  procuredetails,
  orderstatus,
  expensehead,
  emporderdetails,
};
