import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Container,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { appendData } from "../../../Variables/Variables";
import axios from "axios";
import {
  insertAccountDetails,
  methodPost,
} from "../../../API_Service/API_Links";
import { useState } from "react";
import SnackBar from "../../SnackBar";

export default function AddBank() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    Branch: "",
    AccountName: "",
    AccountHolder: "",
    AliasName: "",
    ManualAlias: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const AddNewAccount = () => {
    const obj = {
      AccountNumber: data.AccountNumber,
      IFSCCode: data.IFSCCode,
      BankName: data.BankName,
      Branch: data.Branch,
      AccountName: data.AccountName,
      AccountHolder: data.AccountHolder,
      AliasName: data.ManualAlias !== "" ? data.ManualAlias : data.AliasName,
    };
    const ldata = appendData(obj);
    axios({
      method: methodPost,
      url: insertAccountDetails,
      data: ldata,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData({
            AccountNumber: "",
            IFSCCode: "",
            BankName: "",
            Branch: "",
            AccountName: "",
            AccountHolder: "",
            AliasName: "",
            ManualAlias: "",
          });
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  return (
    <Box p={2}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

      <Container>
        <Box component={Card} p={4} className="cardBoxshadow">
          <Box
            sx={{
              border: "1px solid black",
              p: 3,
              borderColor: "#d2cbcb;",
              borderRadius: "4px",
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="AccountNumber"
                  label="Account Number"
                  fullWidth
                  size="small"
                  value={data.AccountNumber}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue.match(/^[0-9]+$/)) {
                      setData({ ...data, AccountNumber: parseInt(newValue) });
                    } else {
                      setData({ ...data, AccountNumber: "" });
                    }
                  }}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="IFSCCode"
                  label="IFSC Code"
                  fullWidth
                  size="small"
                  value={data.IFSCCode}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="BankName"
                  label="Bank Name"
                  fullWidth
                  size="small"
                  value={data.BankName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Branch"
                  label="Branch"
                  fullWidth
                  size="small"
                  value={data.Branch}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="AccountName"
                  label="Account Name"
                  fullWidth
                  value={data.AccountName}
                  size="small"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo
                  options={["CC", "CA"]}
                  value={data.AliasName}
                  onChange={(event, value) =>
                    setData({ ...data, AliasName: value })
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      onChange={(e) =>
                        setData({ ...data, ManualAlias: e.target.value })
                      }
                      fontSize="small"
                      label="Alias"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={data.AccountHolder}
                  options={["Igreen", "Igreen Tec"]}
                  onChange={(event, value) =>
                    setData({ ...data, AccountHolder: value })
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      fontSize="small"
                      label="Account Holder"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="center" mt={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                display="flex"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center" py={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={AddNewAccount}
                    fullWidth
                  >
                    Add New Account
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
