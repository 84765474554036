import React, { createContext, useState } from "react";

export const HeadingContext = createContext();

export const HeadingProvider = ({ children }) => {
  const [title, setTitle] = useState("");

  return (
    <HeadingContext.Provider value={{ title, setTitle }}>
      {children}
    </HeadingContext.Provider>
  );
};
