import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Container,
  TablePagination,
  IconButton,
} from "@mui/material";
import {
  expenseDocs,
  getAccountDetails,
  get_all_food_expense,
  get_all_rental_expense,
  get_all_transport_expense,
  get_all_travel_expense,
} from "../../../API_Service/API_Links";
import axios from "axios";
import {
  FilterData,
  FilterSumCat,
  FilterTwoData,
} from "../../FilterData/FilterData";
import Filter from "../../FilterData/Filter";
import SnackBar from "../../SnackBar";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteBank from "./DeleteBank";

export default function BankList() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "GET",
      url: getAccountDetails,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "AccountId");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (i) => {
    navigate("updatebank", { state: { data: i } });
  };

  const handleOpenDialog = (i) => {
    setDialogData(i);
    setOpenDeleteDialog(true);
  };

  return (
    <Box p={2}>
      <Box boxShadow={4} bgcolor="#EDF4F4" className="cardBoxshadow">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <DeleteBank
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setMessage={setMessage}
          setStatus={setStatus}
          setOpen={setOpen}
          setColor={setColor}
          data={dialogData}
        />
        <Container>
          <Box pt={3} pb={4}>
            <Grid container spacing={1}>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table>
                  <TableHead
                    sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                  >
                    <TableRow>
                      {keys.map((key) => (
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          key={key}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{ my: "auto", textTransform: "uppercase" }}
                            >
                              {key.split(/(?=[A-Z])/).join(" ")}
                            </Box>
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        EDIT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        DELETE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          {keys.map((key) => (
                            <TableCell align="center" key={key}>
                              {row[key]}
                            </TableCell>
                          ))}
                          <TableCell
                            onClick={() => handleOpenEdit(row)}
                            align="center"
                          >
                            <IconButton>
                              <ModeEditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            onClick={() => handleOpenDialog(row)}
                            align="center"
                          >
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
