import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from '@mui/material';
import axios from 'axios';
import { deleteAccountDetails, methodPost } from '../../../API_Service/API_Links';

export default function DeleteBank({ data , setMessage, setOpen, setStatus, setColor , openDeleteDialog, setOpenDeleteDialog}) {

    const handleClose = () =>{
        setOpenDeleteDialog(false);
    }

   const DeleteBankData = () =>{
        const ldata = new FormData()
        ldata.append('Id', data.AccountId);
             axios({
                 method: methodPost,
                 url:deleteAccountDetails,
                 data: ldata,
             }).then(res => {
                 if (!res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                 } else {
                    setMessage(res.data.message)
                     setOpen(true)
                     setStatus(false)
                     setColor(false)
                 }
             }).catch(err => {
                 alert('Oops something went wrong ' + err)
             })
   }

  return (
      <Dialog onClose={handleClose} open={openDeleteDialog}>
          <DialogTitle>
            DELETE THIS ACCOUNT PERMANENTLY ?
          </DialogTitle>
          <DialogContent>
           <Stack p={2} spacing={1}>
              <Typography><strong>Account Number</strong> : {data.AccountNumber}</Typography>
              <Typography><strong>Bank</strong> : {data.BankName}</Typography>
              <Typography><strong>Branch</strong> : {data.Branch}</Typography>
           </Stack>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} variant='outlined' color='error'>CLOSE</Button>
              <Button onClick={DeleteBankData} variant='outlined' color='success'>Delete</Button>
          </DialogActions>
      </Dialog>
  )
}
