import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import axios from "axios";
import {
  add_department,
  createDesignationDynamic,
  createRoleDynamic,
  methodPost,
} from "../API_Service/API_Links";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fieldConfig = {
  Role: {
    label: "Role",
    validationRegex: /^[A-Za-z0-9\s]+$/,
    errorMessage: "Forbidden character: %<>$'\"",
    url: createRoleDynamic,
  },
  Designation: {
    label: "Designation",
    validationRegex: /^[A-Za-z0-9\s]+$/,
    errorMessage: "Forbidden character: %<>$'\"",
    url: createDesignationDynamic,
  },
  Department: {
    label: "Department",
    validationRegex: /^[A-Za-z0-9\s]+$/,
    errorMessage: "Forbidden character: %<>$'\"",
    url: add_department,
  },
};

export default function DropDownDialog({
  dropDialogOpen,
  setDropDialogOpen,
  selectedDrop,
  setSelectedDrop,
  DesignationList,
  setMessage,
  setColor,
  setStatus,
  setOpen,
  getDept,
  getDesignation,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedDes, setSelectedDes] = useState(null);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setDropDialogOpen(false);
    setSelectedDrop("");
    setInputValue("");
    setSelectedDes(null);
    getDesignation();
    getDept();
  };
  const handleInputChange = (event) => {
    const newValue = event.target.value;

    if (
      newValue.match(config.validationRegex) ||
      newValue === "" ||
      config.validationRegex.test(newValue)
    ) {
      setError(false);
      setInputValue(newValue);
    } else {
      setError(true);
    }
  };

  const config = fieldConfig[selectedDrop] || {};

  const handleCreate = async () => {
    const sendData = new FormData();
    if (selectedDrop === "Role") {
      sendData.append("designationId", selectedDes.id);
      sendData.append("Role", inputValue);
    } else if (selectedDrop === "Designation") {
      sendData.append("Designation", inputValue);
    } else {
      sendData.append("Department", inputValue);
    }

    try {
      const response = await axios({
        method: methodPost,
        url: config.url,
        data: sendData,
      });

      if (!response.data.error) {
        handleClose();
        setMessage(response.data.message);
        setStatus(true);
        setColor(true);
      } else {
        setMessage(response.data.message);
        setStatus(false);
        setColor(false);
      }
      setOpen(true);
    } catch (error) {
      alert("Oops, something went wrong: " + error.message);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={dropDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Create New Drop"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} p={3}>
            {selectedDrop === "Role" && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={DesignationList}
                onChange={(event, value) => setSelectedDes(value)}
                getOptionLabel={(option) => option.designation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="secondary"
                  />
                )}
              />
            )}
            {selectedDrop && (
              <TextField
                label={config.label || "Input"}
                value={inputValue}
                onChange={handleInputChange}
                error={error}
                helperText={error ? config.errorMessage : ""}
                required
                size="small"
                fullWidth
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
