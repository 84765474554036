import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useTable } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Autocomplete
} from '@mui/material';
import { getAllSiteProject, methodGet } from '../API_Service/API_Links';
import axios from 'axios';

const ExcelReader = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ProjectList, setProjectList] = useState([]);
  const [message, setMessage] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const filteredData = jsonData.slice(12).map(row => row.slice(1));

      const headers = filteredData[0];
      const cols = headers.map((header) => ({
        Header: header.trim(),
        accessor: header.trim(),
      }));

      if (!cols.some(col => col.Header === 'Types')) {
        cols.push({ Header: 'Types', accessor: 'Types' });
      }
      if (!cols.some(col => col.Header === 'Project Code')) {
        cols.push({ Header: 'Project Code', accessor: 'ProjectCode' });
      }

      const rows = filteredData.slice(1).map((row) => {
        const rowObj = {};
        row.forEach((cell, cellIndex) => {
          const header = headers[cellIndex].trim();
          rowObj[header] = typeof cell === 'string' ? cell.trim() : cell;
        });

        rowObj.Types = '';
        rowObj.ProjectCode = '';
        return rowObj;
      });

      setColumns(cols);
      setData(rows);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllSiteProject,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
        } else {
          setMessage(res.data.message);
          setProjectList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const handleInputChange = (rowIndex, accessor, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[rowIndex][accessor] = value;
      return newData;
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(data);

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps()} colSpan={2} style={{ border: '1px solid black', padding: '5px' }}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const columnId = cell.column.id;
                    return (
                      <TableCell {...cell.getCellProps()} colSpan={2} style={{ border: '1px solid black', padding: '5px' }}>
                        {columnId === 'Types' ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={['Office Expense', 'Cash In Hand', 'Creditor']}
                            value={cell.value}
                            onChange={(event, value) => handleInputChange(row.index, 'Types', value)}
                            size='small'
                            sx={{width:200}}
                            renderInput={(params) => <TextField sx={{ width:200 }} fontSize='small' label='Type' color='secondary' {...params} />}
                          />
                        ) : columnId === 'ProjectCode' ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={ProjectList.map((i) => i.projectCode)}
                            value={cell.value}
                            onChange={(event, value) => handleInputChange(row.index, 'ProjectCode', value)}
                            size='small'
                            sx={{width:200}}
                            renderInput={(params) => <TextField sx={{ width:200 }} fontSize='small' label='Project Code' color='secondary' {...params} />}
                          />
                        ) : (
                          cell.render('Cell')
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ExcelReader;
