import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, Stack, TextField, Typography , Box, Container, Card} from '@mui/material';
import { add_amount, expenseDocs, getLedgerNameDropdown, reject_leave, statusUpdateExpense, updateApprovalOfficeExpense } from '../../../API_Service/API_Links';
import { useState } from 'react';
import axios from 'axios';
import {  getAllSiteProject,    getOfficeBalance, insertOfficeExpense,
    getOfficeDropdown, getGroupsDropdown, getLedgerCategory1Dropdown, getLedgerCategory2Dropdown, getLedgerCategory3Dropdown, getLedgerCategory4Dropdown, getLedgerCategory5Dropdown, insertLedgerCreation, methodGet, methodPost } from "../../../API_Service/API_Links";
import { useEffect } from 'react';
import { appendData } from '../../../Variables/Variables';

export default function OfficeApproveDialog({getOffice,  data , setMessage, setOpen, setStatus, setColor , openApproveDialog, setOpenApproveDialog}) {
  
    const handleClose = () =>{
        setOpenApproveDialog(false);
        getOffice();
    }

    const [comments, setComments] = useState('');
    const [amount, setAmount] = useState('');
    const [projectCode, setProjectCode] = useState(null);
    const [ProjectList , setProjectList] = useState([]);
    const[input, setInput] = useState(false);
    const [LedgerName, setLedgerName] = useState(null);
    const [offficeCategory, setoffficeCategory] = useState(null);
    const [group , setGroup] = useState(null);
    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);
    const [category3, setCategory3] = useState(null);
    const [category4, setCategory4] = useState(null);
    const [category5, setCategory5] = useState(null);
    const EmployeeId = localStorage.getItem('EmployeeId');
    const [gst , setGst] = useState(null);
    const [sgst, setSGST] = useState('');
    const [cgst, setCGST] = useState('');
    const [igst, setIGST] = useState('');
    const [spendDate, setSpendDate] = useState('');
    const [ledgerList  , setLedgerList] = useState([]);
    const [groupList  , setGroupList] = useState([]);
    const [offficeCategoryList  , setoffficeCategoryList] = useState([]);
    const [Category1List  , setCategory1List] = useState([]);
    const [Category2List  , setCategory2List] = useState([]);
    const [Category3List  , setCategory3List] = useState([]);
    const [Category4List  , setCategory4List] = useState([]);
    const [Category5List  , setCategory5List] = useState([]);
    const [Balance , setBalance] = useState('');

    const [ManualLedgerName, setManualLedgerName] =useState(null);
    const [Manualgroup , setManualGroup] = useState(null);
    const [Manualcategory1, setManualCategory1] = useState(null);
    const [Manualcategory2, setManualCategory2] = useState(null);
    const [Manualcategory3, setManualCategory3] = useState(null);
    const [Manualcategory4, setManualCategory4] = useState(null);
    const [Manualcategory5, setManualCategory5] = useState(null);

    useEffect(() => {
        axios({
          method: methodGet,
          url: getAllSiteProject,
        })
          .then((res) => {
            if (res.data.error) {
              setMessage(res.data.message);
              setStatus(false);
              setColor(false);
            } else {
              setMessage(res.data.message);
              setProjectList(res.data.data);
              setStatus(true);
              setColor(true);
            }
          })
          .catch((err) => {
            alert("Oops something went wrong " + err);
          });
    }, []);

    useEffect(() => {
      axios({
        method: methodGet,
        url: getOfficeDropdown,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setoffficeCategoryList(res.data.data);
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getLedgerNameDropdown,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setLedgerList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, []);

  useEffect(() => {
      const sendData = new FormData();
      sendData.append('LedgerNameId', (ManualLedgerName === null && LedgerName !== undefined && LedgerName !== null) ? LedgerName?.LedgerNameId : "" );
      axios({
        method: methodPost,
        url: getGroupsDropdown,
        data:sendData
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setStatus(false);
            setColor(false);
          } else {
            setMessage(res.data.message);
            setGroupList(res.data.data);
            if(LedgerName !== null){
              setGroup(res.data.data[0])
            }
            else{
              setGroup(null)
            }
            setStatus(true);
            setColor(true);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
  }, [LedgerName]);


 


  useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerGroupsId', (Manualgroup === null && group !== undefined &&  group !== null) ? group?.LedgerGroupsId : "" )
    axios({
      method: methodPost,
      url: getLedgerCategory1Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory1List(res.data.data);
          if(group !== null){
            setCategory1(res.data.data[0])
          }
          else{
            setCategory1(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [group]);


useEffect(() => {
  const sendData = new FormData()
  sendData.append('LedgerCategoryId1', (Manualcategory1 === null && category1 !== undefined &&  category1 !== null) ?  category1?.LedgerCategoryId1 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory2Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory2List(res.data.data);
          if(category1 !== null){
            setCategory2(res.data.data[0])
          }
          else{
            setCategory2(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category1]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId2', (Manualcategory2 === null && category2 !== undefined &&  category2 !== null) ? category2?.LedgerCategoryId2 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory3Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory3List(res.data.data);
          if(category2 !== null){
            setCategory3(res.data.data[0])
          }
          else{
            setCategory3(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category2]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId3',(Manualcategory3 === null && category3 !== undefined &&  category3 !== null) ? category3?.LedgerCategoryId3 : "")
    axios({
      method: methodPost,
      url: getLedgerCategory4Dropdown,
      data:sendData
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setCategory4List(res.data.data);
          if(category3 !== null){
            setCategory4(res.data.data[0])
          }
          else{
            setCategory4(null)
          }
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
}, [category3]);

useEffect(() => {
    const sendData = new FormData()
    sendData.append('LedgerCategoryId4', (Manualcategory4 === null && category4 !== undefined &&  category4 !== null) ? category4?.LedgerCategoryId4 : "")
  axios({
    method: methodPost,
    url: getLedgerCategory5Dropdown,
    data:sendData
  })
    .then((res) => {
      if (res.data.error) {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      } else {
        setMessage(res.data.message);
        setCategory5List(res.data.data);
        if(category4 !== null){
          setCategory5(res.data.data[0])
        }
        else{
          setCategory5(null)
        }
        setStatus(true);
        setColor(true);
      }
    })
    .catch((err) => {
      alert("Oops something went wrong " + err);
    });
}, [category4]);

useEffect(() => {
  const sendData = new FormData()
  sendData.append('EmployeeId' , EmployeeId )
  axios({
    method: methodPost,
    url: getOfficeBalance,
    data:sendData
  })
    .then((res) => {
      if (res.data.error) {
        setMessage(res.data.message);
        setStatus(false);
        setColor(false);
      } else {
        setMessage(res.data.message);
        setBalance(res.data.data);
        setStatus(true);
        setColor(true);
      }
    })
    .catch((err) => {
      alert("Oops something went wrong " + err);
    });
}, []);

    const AddNewLedger = () =>{
      const obj = {
        Types:gst,
        SGST:sgst,
        CGST:cgst,
        IGST:igst,
        Amount:data?.Amount,
        EmployeeId:data?.EmployeeId,
        OfficeExpenseId:data?.Id,
        OfficeBalanceId:Balance?.OfficeBalanceId,
        LedgerNameId:LedgerName?.LedgerNameId || null ,
        LedgerName:ManualLedgerName !== null ? ManualLedgerName : LedgerName?.LedgerName ? LedgerName?.LedgerName : "" ,
        LedgerGroups:Manualgroup != null ? Manualgroup : group?.LedgerGroups ? group?.LedgerGroups : "" ,
        LedgerCategory1:Manualcategory1 != null ? Manualcategory1 :  category1?.LedgerCategory1 ? category1?.LedgerCategory1 :"",
        LedgerCategory2:Manualcategory2 != null ? Manualcategory2 : category2?.LedgerCategory2 ? category2?.LedgerCategory2  : "",
        LedgerCategory3:Manualcategory3 != null ? Manualcategory3 : category3?.LedgerCategory3 ? category3?.LedgerCategory3 : "",
        LedgerCategory4:Manualcategory4 !== null ? Manualcategory4 : category4?.LedgerCategory4 ? category4?.LedgerCategory4 : "",
        LedgerCategory5:Manualcategory5 !== null ? Manualcategory5 : category5?.LedgerCategory5 ? category5?.LedgerCategory5 : "",
      }
      const ldata = appendData(obj);
           axios({
               method: methodPost,
               url: updateApprovalOfficeExpense,
               data: ldata,
           }).then(res => {
               if (!res.data.error) {
                 setMessage(res.data.message)
                 setOpen(true)
                 setStatus(true)
                 setColor(true)
                 setProjectCode(null);
                 setoffficeCategory(null);
                 setComments('')
                 setAmount('')
                 setCategory1(null);
                 setCategory2(null);
                 setCategory3(null);
                 setCategory4(null);
                 setCategory5(null);
                 setGroup(null);
                 setGst(null);
                 setManualCategory1(null)
                 setManualCategory2(null)
                 setManualCategory3(null)
                 setManualCategory4(null)
                 setManualCategory5(null)
                 setManualLedgerName(null)
                 setManualGroup(null)
                 setIGST('')
                 setSpendDate('');
                 setCGST('')
                 setInput(true)
                 setSGST('')
                 setLedgerName(null);
                 handleClose();
               } else {
                 setMessage(res.data.message)
                   setOpen(true)
                   setStatus(false)
                   setColor(false)
               }
           }).catch(err => {
               alert('Oops something went wrong ' + err)
           })
     }
  
    return (
        <div>
        <Dialog
         fullWidth
         maxWidth={'lg'}
          open={openApproveDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Approve This Request</DialogTitle>
          <DialogContent>

          <Container>


       <Box
        sx={{
        border: "1px solid black",
        p: 3,
        borderColor: "#d2cbcb;",
        borderRadius: "4px",
        mt: 1,
         }}
        >
            <Grid container spacing={3}>
  

            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
            name="ProjectCode"
            label="ProjectCode"
            value={data.ProjectCode}
            disabled
            fullWidth
            size='small'
            
          />
      </Grid> */}

    <Grid item xs={12} sm={6} md={4} lg={4}>
    <TextField
            name="Category"
            label="Category"
            value={data.Category}
            disabled
            fullWidth
            size='small'
            
          />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={4}>
    <TextField
            name="comments"
            label="Comments"
            value={data.Comments}
            disabled
            fullWidth
            size='small'
            
          />
</Grid>

<Grid item xs={12} sm={6} md={4} lg={4}>
    <TextField
            name="spendDate"
            label="Spend Date"
            value={data.SpendDate}
            type='date'
            InputLabelProps={{
              shrink:true
            }}
            disabled
            fullWidth
            size='small'
            
          />
</Grid>

<Grid item xs={12} sm={6} md={4} lg={4}>
 <TextField
            name="Amount"
            label="Amount"
            value={data.Amount}
            fullWidth
            
            disabled
            size='small'
          />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
      </Grid>
  
    <Grid item xs={12} sm={6} md={4} lg={4}>
       <Autocomplete
        disablePortal
        id="combo-box-demo"
        key={input}
        options={['GST', 'NON-GST']}
        value={gst}
        onChange={(event, value) => setGst(value)}
        size='small'
        renderInput={(params) => <TextField fontSize='small' label='GST/NON-GST' color='secondary' {...params} />}
          />
        </Grid>
    
  
  {
    (gst === 'GST' && gst !== null && gst !== undefined ) && <>
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
              name="name"
              label="SGST (%)"
              fullWidth
              size='small'
              
              onChange={(e) => {
                const newValue = e.target.value;
                // Regular expression for allowing decimal numbers
                if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                  setSGST(newValue);
                } else {
                  setSGST('');
                }
              }}
              value={sgst}
            />
        </Grid>
  
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
              name="name"
              label="CGST (%)"
              fullWidth
              size='small'
              value={cgst}
              
              onChange={(e) => {
                const newValue = e.target.value;
                // Regular expression for allowing decimal numbers
                if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                  setCGST(newValue);
                } else {
                  setCGST('');
                }
              }}
            />
        </Grid>
  
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
              name="name"
              label="IGST (%)"
              fullWidth
              size='small'
              
              onChange={(e) => {
                const newValue = e.target.value;
                // Regular expression for allowing decimal numbers
                if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                  setIGST(newValue);
                } else {
                  setIGST('');
                }
              }}
              value={igst}
            />
        </Grid>
        </>
  }
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Autocomplete
      disablePortal
      id="combo-box-demo"
      key={input}
      options={ledgerList}
      value={LedgerName}
      freeSolo
      getOptionLabel={(option)=>option.LedgerName}
      onChange={(event, value) => setLedgerName(value ?? value)}
      size='small'
      renderInput={(params) => <TextField onChange={(e)=>setManualLedgerName(e.target.value)}  sx={{my:1}} fontSize='small' label='Ledger' color='secondary' {...params} />}
        />
             </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
        disablePortal
        id="combo-box-demo"
        key={input}
        options={groupList}
        value={group}
        freeSolo
        getOptionLabel={(option)=>option.LedgerGroups}
        onChange={(event, value) => setGroup(value)}
        size='small'
        renderInput={(params) => <TextField onChange={(e)=>setManualGroup(e.target.value)} fontSize='small' label='Select Group' color='secondary' {...params} />}
          />
             </Grid>
             <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
          disablePortal
          id="combo-box-demo"
          key={input}
          options={Category1List}
           value={category1}
           freeSolo
          getOptionLabel={(option)=>option.LedgerCategory1}
          onChange={(event, value) =>{
              setCategory1(value)
             }}
          size='small'
          renderInput={(params) => <TextField onChange={(e)=>setManualCategory1(e.target.value)} fontSize='small' label='Category 1' color='secondary' {...params} />}
          />
             </Grid>
             <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
            disablePortal
            id="combo-box-demo"
            key={input}
            options={Category2List}
            value={category2}
            freeSolo
            getOptionLabel={(option)=>option.LedgerCategory2}
            onChange={(event, value) =>{
            setCategory2(value);
            }}
            size='small'
            renderInput={(params) => <TextField onChange={(e)=>setManualCategory2(e.target.value)} fontSize='small' label='Category 2' color='secondary' {...params} />}
            />
             </Grid>
             <Grid item xs={12} sm={6} md={4} lg={4}>
              
  <Autocomplete
            disablePortal
            id="combo-box-demo"
            key={input}
            options={Category3List}
            value={category3}
            freeSolo
            getOptionLabel={(option)=>option.LedgerCategory3}
            onChange={(event, value) => {
              setCategory3(value)
            }}
            size='small'
            renderInput={(params) => <TextField onChange={(e)=>setManualCategory3(e.target.value)}  fontSize='small' label='Category 3' color='secondary' {...params} />}
            />
             </Grid>
  
             <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
            disablePortal
            id="combo-box-demo"
            key={input}
            options={Category4List}
            value={category4}
            freeSolo
            getOptionLabel={(option)=>option.LedgerCategory4}
            onChange={(event, value) =>{
               setCategory4(value)
              }}
            size='small'
            renderInput={(params) => <TextField onChange={(e)=>setManualCategory4(e.target.value)} fontSize='small' label='Category 4' color='secondary' {...params} />}
            />
             </Grid>
  
             <Grid item xs={12} sm={6} md={4} lg={4}>
             <Autocomplete
            disablePortal
            id="combo-box-demo"
            key={input}
            options={Category5List}
            value={category5}
            freeSolo
            getOptionLabel={(option)=>option.LedgerCategory5}
            onChange={(event, value) =>{
               setCategory5(value)
              }}
            size='small'
            renderInput={(params) => <TextField onChange={(e)=>setManualCategory5(e.target.value)} fontSize='small' label='Category 5' color='secondary' {...params} />}
            />
             </Grid>
  
         </Grid>
          </Box>
      
          </Container>
          </DialogContent>
          <DialogActions>
            <Box p={2}>
            <Button variant='contained' color='error' sx={{marginRight:2}} onClick={handleClose}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={AddNewLedger}>Approve</Button>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
  )
}
