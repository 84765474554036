import { lazy, useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Layout from "./Layout";
import withSuspense from "./WithSupense";
import FactoryProjectAssignTab from "./Components/FactoryProjectComps/FactoryProjectAssignTab";
import CommercialFromSalesPage from "./Components/FactoryProjectComps/CommercialFromSales";
import TechnicalFromCommercialPage from "./Components/FactoryProjectComps/TechnicalFromCommercial";
import MaterialInwards from "./Components/FactoryProjectComps/MaterialInwards";
import MaterialIssueProjectList from "./Components/FactoryProjectComps/MaterialIssueProjectList";
import StoreFactoryProject from "./Components/FactoryProjectComps/StoreFactoryProject";
import PurchaseTable from "./Components/FactoryProjectComps/PurchaseTable";
import CommercialFromTechnicalPage from "./Components/FactoryProjectComps/CommercialFromTechnical";
import ProjectManagerFromCommercialPage from "./Components/FactoryProjectComps/ProjectManagerFromCommercial";
import CommercialFromProjectManagerPage from "./Components/FactoryProjectComps/CommercialFromProjectManager";
import EditLedger from "./Components/FinanceComps/Ledger/EditLedger";
import ViewLedger from "./Components/FinanceComps/Ledger/ViewLedger";
import AllCashInHand from "./Components/FinanceComps/CashOnHand/AllCashInHand";
import TaskCreationTab from "./Components/TaskComps/TaskCreationTab";
import Transfer from "./Components/FinanceComps/Bank/Transfer";
import AddBank from "./Components/FinanceComps/Bank/AddBank";
import BankList from "./Components/FinanceComps/Bank/BankList";
import BankBalanaceSummary from "./Components/FinanceComps/Bank/BankBalanaceSummary";
import Transaction from "./Components/FinanceComps/Bank/Transaction";
import Debit from "./Components/FinanceComps/Bank/Debit";
import BalanceCheck from "./Components/FinanceComps/Bank/BalanceCheck";
import NewOfficeExpense from "./Components/FinanceComps/OfficeExpense/NewOfficeExpense";
import AllOfficeExpense from "./Components/FinanceComps/OfficeExpense/AllOfficeExpense";
import ApprovedOfficeExpense from "./Components/FinanceComps/OfficeExpense/ApproveOfficeExpense";
import CreateIncentive from "./Components/FinanceComps/IncentivePageTab/CreateIncentive";
import ApproveFundRequest from "./Components/FundRequest/ApproveFundRequest";
import FundRequestView from "./Components/FundRequest/FundRequestView";
import Form16Add from "./Components/FinanceComps/Form16/Form16Add";
import Form16Get from "./Components/FinanceComps/Form16/Form16Get";
import AddTab from "./Components/HRMSComps/OnBoardTabPanels/AddTab";
import CreateCTCTab from "./Components/HRMSComps/OnBoardTabPanels/CreateCTCTab";
import EditTab from "./Components/HRMSComps/OnBoardTabPanels/EditTab";
import DeleteTab from "./Components/HRMSComps/OnBoardTabPanels/DeleteTab";
import OnDutyPendingPage from "./Components/HRMSComps/OnDutyTabPanels/Pending";
import OnDutyApprovedPage from "./Components/HRMSComps/OnDutyTabPanels/Approved";
import OnDutyRejectedPage from "./Components/HRMSComps/OnDutyTabPanels/Rejected";
import AssignedTaskTable from "./Components/TaskComps/AssignedTaskTable";
import Task1Page from "./Components/TaskComps/Task1Tab";
import Task2Page from "./Components/TaskComps/Task2Tab";
import Task3Page from "./Components/TaskComps/Task3Tab";
import StartedTaskPage from "./Components/TaskComps/StartedTask";
import EndedTaskPage from "./Components/TaskComps/EndedTask";
import OverDueTaskPage from "./Components/TaskComps/OverDueTask";
import CreateEvent from "./Components/AnnouncementComps/Events/CreateEvent";
import SeeAllEvent from "./Components/AnnouncementComps/Events/SeeAllEvent";
import CreateEMI from "./Components/AnnouncementComps/Reminder/CreateEMI";
import CreateReminder from "./Components/AnnouncementComps/Reminder/CreateReminder";
import AllReminders from "./Components/AnnouncementComps/Reminder/AllReminders";
import CreateCalender from "./Components/AnnouncementComps/Calendar/Calender";
import AnnualCalender from "./Components/AnnouncementComps/Calendar/AnnualCalendar";
import UpdateCalender from "./Components/AnnouncementComps/Calendar/UpdateCalendar";
import AssignedFactoryProjectTable from "./Components/FactoryProjectComps/AssignedFactoryProjectTable";
import EmployeeExpensePage from "./Components/ExpensePageComps/EmployeeExpensePage";

// ==============================|| Pages ||============================== //

const Login = withSuspense(lazy(() => import("./Pages/LoginPage")));
const HomePage = withSuspense(lazy(() => import("./Pages/HomePage")));
const ExpensePage = withSuspense(lazy(() => import("./Pages/ExpensePage")));
const ProjectSiteAssignTab = withSuspense(
  lazy(() => import("./Pages/ProjectSiteAssignTab"))
);
const AssignedSiteProjectTable = withSuspense(
  lazy(() => import("./Pages/AssignedSiteProjectTable"))
);
const DashBoardPage = withSuspense(lazy(() => import("./Pages/DashBoardPage")));

// Finance Page
const PayslipSection = withSuspense(
  lazy(() => import("./Components/FinanceComps/PayslipSection"))
);
const PayoutSection = withSuspense(
  lazy(() => import("./Components/FinanceComps/PayoutSection"))
);
const Last3PayoutSection = withSuspense(
  lazy(() => import("./Components/FinanceComps/Last3PayoutSection"))
);
const IncentiveSection = withSuspense(
  lazy(() =>
    import("./Components/FinanceComps/IncentivePageTab/IncentiveSection")
  )
);
const CTCSection = withSuspense(
  lazy(() => import("./Components/FinanceComps/CTCSection"))
);

// HRMS PAGE
const Complaints = withSuspense(
  lazy(() => import("./Components/HRMSComps/Complaints/Complaints"))
);
const Hierarchy = withSuspense(
  lazy(() => import("./Components/HRMSComps/Hierarchy"))
);
const LeaveApproval = withSuspense(
  lazy(() => import("./Components/HRMSComps/LeaveTabPanels/LeaveApproval"))
);

const Profile = withSuspense(
  lazy(() => import("./Components/HRMSComps/Profile"))
);
const ViewLeaves = withSuspense(
  lazy(() => import("./Components/HRMSComps/LeaveTabPanels/ViewLeaves"))
);

// Component Section
const EditEmployeeEditForm = withSuspense(
  lazy(() =>
    import("./Components/HRMSComps/OnBoardTabPanels/EditEmployeeEditForm")
  )
);
const SiteProjectsEditForm = withSuspense(
  lazy(() => import("./Components/SiteProjectComps/SiteProjectsEditForm"))
);
const SiteProjectsViewForm = withSuspense(
  lazy(() => import("./Components/SiteProjectComps/SiteProjectsViewForm"))
);
const ProfileEmployeeDialog = withSuspense(
  lazy(() => import("./Components/HRMSComps/ProfileEmployeeView"))
);
const FactoryProjectEditForm = withSuspense(
  lazy(() => import("./Components/FactoryProjectComps/FactoryProjectEditForm"))
);
const FactoryProjectViewForm = withSuspense(
  lazy(() => import("./Components/FactoryProjectComps/FactoryProjectViewForm"))
);
const PurchaseSection = withSuspense(
  lazy(() => import("./Components/FactoryProjectComps/PurchaseSection"))
);
const TaskEditForm = withSuspense(
  lazy(() => import("./Components/TaskComps/TaskEditForm"))
);

const EditEmployeeViewForm = withSuspense(
  lazy(() =>
    import("./Components/HRMSComps/OnBoardTabPanels/EditEmployeeViewForm")
  )
);

const StoreEditViewPage = withSuspense(
  lazy(() => import("./Components/FactoryProjectComps/StoreEditViewPage"))
);
const MaterialIssued = withSuspense(
  lazy(() => import("./Components/FactoryProjectComps/MaterialIssued"))
);
const EditAccess = withSuspense(lazy(() => import("./Pages/EditAccess")));
const ExpensesTab = withSuspense(
  lazy(() => import("./Components/ExpensePageComps/ExpensesTab"))
);

const UpdateBank = withSuspense(
  lazy(() => import("./Components/FinanceComps/Bank/UpdateBank"))
);

const CashOnHand = withSuspense(
  lazy(() => import("./Components/FinanceComps/CashOnHand/CashOnHand"))
);

const MaintenanceTab = withSuspense(
  lazy(() => import("./Components/ExpensePageComps/MaintenanceTab"))
);
const UnderMaintenanceEditForm = withSuspense(
  lazy(() =>
    import("./Components/ExpensePageComps/Maintenance/UnderMaintenanceEditForm")
  )
);
const NewExpensesTab = withSuspense(
  lazy(() => import("./Components/ExpensePageComps/NewExpensesTab"))
);

export const startUrl = `/`;

// ==============================|| ROUTING RENDER ||============================== //

function Routes() {
  const auth = localStorage.getItem("IgreenAuth");
  const [successOpen, setsuccessOpen] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [successStatus, setsuccessStatus] = useState(false);
  const [successColor, setsuccessColor] = useState(false);
  const role = JSON.parse(localStorage.getItem("role"));
  const admin = role === "Super Admin" || role === "Human Resources";

  const maxInactiveTime = 40 * 60 * 1000; // 60 minutes in milliseconds

  const [lastActivity, setLastActivity] = useState(Date.now());

  // Handle user activity
  useEffect(() => {
    const handleUserActivity = () => {
      setLastActivity(Date.now());
    };

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keypress", handleUserActivity);

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    };
  }, []);

  // Check for inactivity and redirect if necessary
  useEffect(() => {
    const checkInactivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastActivity > maxInactiveTime) {
        // Redirect to login page
        window.location.href = startUrl;
      }
    };

    const interval = setInterval(checkInactivity, 10000); // Check every 10 seconds

    return () => clearInterval(interval);
  }, [lastActivity]);

  return useRoutes([
    // {
    //     path: `*`,
    //     element: <Pagenotfound />
    // },
    {
      path: startUrl,
      element: (
        <Login
          setsuccessOpen={setsuccessOpen}
          setsuccessMessage={setsuccessMessage}
          setsuccessStatus={setsuccessStatus}
          setsuccessColor={setsuccessColor}
        />
      ),
    },
    {
      path: `${startUrl}home`,
      element: auth ? (
        <Layout
          successOpen={successOpen}
          setsuccessOpen={setsuccessOpen}
          successMessage={successMessage}
          successStatus={successStatus}
          successColor={successColor}
        />
      ) : (
        <Navigate to={startUrl} />
      ),

      children: [
        { path: `${startUrl}home`, element: <HomePage /> },
        { path: `logout`, element: <Navigate to={startUrl} /> },
        { path: `dashboard`, element: <DashBoardPage /> },
        { path: `siteproject`, element: <ProjectSiteAssignTab /> },
        { path: `assignedsiteproject`, element: <AssignedSiteProjectTable /> },
        {
          path: `assignedsiteproject/editsiteproject`,
          element: <SiteProjectsEditForm />,
        },
        {
          path: `assignedsiteproject/viewsiteproject`,
          element: <SiteProjectsViewForm />,
        },
        { path: `factoryproject`, element: <FactoryProjectAssignTab /> },
        {
          path: `assignedfactoryproject`,
          element: <AssignedFactoryProjectTable />,
        },

        {
          path: `assignedfactoryproject/editFactproject`,
          element: <FactoryProjectEditForm />,
        },
        {
          path: `assignedfactoryproject/viewFactproject`,
          element: <FactoryProjectViewForm />,
        },
        {
          path: `commercialfromsales`,
          element: <CommercialFromSalesPage />,
        },
        {
          path: `technicalfromcommercial`,
          element: <TechnicalFromCommercialPage />,
        },
        {
          path: `materialinwards`,
          element: <MaterialInwards />,
        },
        {
          path: `materialissued`,
          element: <MaterialIssueProjectList />,
        },
        {
          path: `store`,
          element: <StoreFactoryProject />,
        },
        {
          path: `purchase`,
          element: <PurchaseTable />,
        },
        {
          path: `commercialfromtechnical`,
          element: <CommercialFromTechnicalPage />,
        },
        {
          path: `projectmanagerfromcommercial`,
          element: <ProjectManagerFromCommercialPage />,
        },
        {
          path: `commercialfromprojectmanager`,
          element: <CommercialFromProjectManagerPage />,
        },
        { path: `project/viewstore`, element: <StoreEditViewPage /> },
        { path: `materialissued/issuematerial`, element: <MaterialIssued /> },
        { path: `purchase/addquote`, element: <PurchaseSection /> },

        { path: `payslip`, element: <PayslipSection /> },
        { path: `payout`, element: <PayoutSection /> },
        { path: `createincentive`, element: <CreateIncentive /> },
        { path: `allincentives`, element: <IncentiveSection /> },

        { path: `expense`, element: <ExpensePage /> },
        { path: `expense/newexpense`, element: <NewExpensesTab /> },
        { path: `expense/allexpense`, element: <ExpensesTab /> },
        { path: `expense/maintenance`, element: <MaintenanceTab /> },
        {
          path: `expense/maintenance/editundermaintenance`,
          element: <UnderMaintenanceEditForm />,
        },
        {
          path:'expense/EmplyeeExpense',
          element: <EmployeeExpensePage />,
        },
        { path: `transfer`, element: <Transfer /> },
        { path: `bank`, element: <AddBank /> },
        { path: `allbank`, element: <BankList /> },
        { path: `bankbalance`, element: <BalanceCheck /> },
        { path: `balancesummary`, element: <BankBalanaceSummary /> },
        { path: `credit`, element: <Transaction /> },
        { path: `debit`, element: <Debit /> },
        { path: `allbank/updatebank`, element: <UpdateBank /> },

        { path: `ledger`, element: <EditLedger /> },
        { path: `viewledger`, element: <ViewLedger /> },

        { path: `cashinhand`, element: <CashOnHand /> },
        { path: `spendlist`, element: <AllCashInHand /> },

        { path: `officeexpense`, element: <NewOfficeExpense /> },
        { path: `allofficeexpenses`, element: <AllOfficeExpense /> },
        { path: `approvedofficeexpenses`, element: <ApprovedOfficeExpense /> },

        { path: `fundrequest`, element: <ApproveFundRequest /> },
        { path: `viewfundrequest`, element: <FundRequestView /> },

        { path: `newtask`, element: <TaskCreationTab /> },
        { path: `assignedtask`, element: <AssignedTaskTable /> },
        { path: `taskone`, element: <Task1Page /> },
        { path: `tasktwo`, element: <Task2Page /> },
        { path: `taskthree`, element: <Task3Page /> },
        { path: `startedtask`, element: <StartedTaskPage /> },
        { path: `endedtask`, element: <EndedTaskPage /> },
        { path: `odtask`, element: <OverDueTaskPage /> },
        { path: `assignedtask/edittask`, element: <TaskEditForm /> },

        // { path: `assignedtask/viewtask`, element: <TaskViewForm /> },

        { path: `complaints`, element: <Complaints /> },

        { path: `leaves`, element: <LeaveApproval /> },
        { path: `viewleaves`, element: <ViewLeaves /> },

        { path: `onboard`, element: <AddTab /> },
        { path: `createctc`, element: <CreateCTCTab /> },
        { path: `editemployee`, element: <EditTab /> },
        { path: `deleteemployee`, element: <DeleteTab /> },
        {
          path: `editemployee/updateemployee`,
          element: <EditEmployeeEditForm />,
        },
        {
          path: `editemployee/viewemployee`,
          element: <EditEmployeeViewForm />,
        },
        {
          path: `profile`,
          element: admin ? <Profile /> : <ProfileEmployeeDialog />,
        },
        {
          path: `adminemployeeviewprofile`,
          element: <ProfileEmployeeDialog />,
        },
        { path: `hierarchy`, element: <Hierarchy /> },
        { path: `last3pay`, element: <Last3PayoutSection /> },
        { path: `form16`, element: <Form16Add /> },
        { path: `tableform`, element: <Form16Get /> },
        { path: `ctc`, element: <CTCSection /> },
        { path: `pendingod`, element: <OnDutyPendingPage /> },
        { path: `approvedod`, element: <OnDutyApprovedPage /> },
        { path: `rejectedod`, element: <OnDutyRejectedPage /> },
        { path: `access`, element: <EditAccess /> },

        { path: `createevent`, element: <CreateEvent /> },
        { path: `allevents`, element: <SeeAllEvent /> },

        { path: `emi`, element: <CreateEMI /> },
        { path: `general`, element: <CreateReminder /> },
        { path: `allreminder`, element: <AllReminders /> },

        { path: `createcalendar`, element: <CreateCalender /> },
        { path: `annualcalendar`, element: <AnnualCalender /> },
        { path: `updatecalendar`, element: <UpdateCalender /> },
      ],
    },
  ]);
}

export default Routes;
