import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SnackBar from "../../SnackBar";
import {
  addform16,
  getAllEmployeeName,
  get_form16,
  methodGet,
} from "../../../API_Service/API_Links";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FilterMaterial } from "../../FilterData/FilterData";
import { Stack } from "react-bootstrap";

export default function Form16Get() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);

  //   useEffect(() => {
  //     const sendData = new FormData();
  //     sendData.append('ExpenseCategoryId', 6)
  //     axios({
  //       method: methodPost,
  //       url: fetch_sub1_category,
  //       data: sendData
  //     })
  //       .then((res) => {
  //         if (res.data.error) {
  //           // setMessage(res.data.message);
  //           // setStatus(false);
  //           // setColor(false);
  //           setTypeList([])
  //         } else {
  //           // setMessage(res.data.message);
  //           setTypeList(res.data.data);
  //           // setStatus(true);
  //           // setColor(true);
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Oops something went wrong " + err);
  //         console.log("chip1", err);
  //       });
  //   }, [Type]);

  useEffect(() => {
    const sendData = new FormData();
    sendData.append("ExpenseCategoryId", 6);
    axios({
      method: "POST",
      url: get_form16,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setdata(res.data.data);
          //   setOpen(true);
          //   setStatus(true);
          //   setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const statusMapping = {
    1: "In-Progress",
    2: "Purchase Pending",
    3: "Finance Pending",
    4: "Finance Approved",
    5: "Raw Material Available",
    6: "Assigned To Employee",
    7: "Completed",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (i) => {
    navigate("viewstore", { state: { id: i.ProjectId } });
  };

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "MaterialId");

  return (
    <Box p={2}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />
      <Container>
        <Box mt={2} py={4}>
          <Grid container>
            <TableContainer sx={{ border: "1px solid silver" }}>
              <Table>
                <TableHead
                  sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                >
                  <TableRow>
                    {keys.map((key) => (
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                        key={key}
                      >
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Box sx={{ my: "auto", textTransform: "uppercase" }}>
                            {key.split(/(?=[A-Z])/).join(" ")}
                          </Box>
                          {/* {key === "MaterialCode" && (
                    <Box>
                      <Filter search={materialCodeSearch} setSearch={setmaterialCodeSearch} setPage={setPage} />
                    </Box>
                  )
                  } */}
                          {/* {key === "MaterialName" && (
                    <Box>
                      <Filter search={materialNameSearch} setSearch={setmaterialNameSearch} setPage={setPage} />
                    </Box>
                  )
                  } */}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FilterMaterial(data)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        {keys.map((key) => (
                          <TableCell align="center" key={key}>
                            {key === "FactoryProjectsItems" ? (
                              row[key] ? (
                                <Stack>
                                  {row[key]?.map((i) => {
                                    <Typography>{i}</Typography>;
                                  })}
                                </Stack>
                              ) : (
                                "-"
                              )
                            ) : (
                              row[key]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={FilterMaterial(data).length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
