import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
} from "@mui/material";
import {
  get_Employees_available_balance,
  get_project_available_balance,
  methodGet,
} from "../../API_Service/API_Links";
import SnackBar from "../SnackBar";
import axios from "axios";
import Filter from "../FilterData/Filter";

const EmployeeTable = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [empname, setEmpname] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [employeeNameSearch, setEmployeeNameSearch] = useState("");
  const [dialogSearch, setDialogSearch] = useState(""); // New state for dialog search
  const [availbleExpense, setAvailableExpense] = useState("");

  const handleClickOpen = (employee) => {
    setSelectedEmployee(employee);
    setOpenDialog(true);
    getEmployeeAvailbleBalance(employee);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
    setDialogSearch(""); // Reset dialog search when closing
  };

  useEffect(() => {
    axios({
      method: methodGet,
      url: get_Employees_available_balance,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpenSnackbar(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmpname(res.data.data);
          setOpenSnackbar(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("Error fetching employee data", err);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterEmp = (data, search) => {
    return data?.filter((employee) => {
      return (
        employee.EmployeeName.toLowerCase().includes(search.toLowerCase()) ||
        employee.EmployeeId.toLowerCase().includes(search.toLowerCase())
      );
    });
  };

  const filterProject = (data, search) => {
    return data?.filter((project) => {
      return (
        project.ProjectName.toLowerCase().includes(search.toLowerCase()) ||
        project.AvailableBalance.toString().includes(search)
      );
    });
  };

  // Applying the filter function
  const filteredEmployees = filterEmp(empname, employeeNameSearch);

  const getEmployeeAvailbleBalance = async (employee) => {
    const formData = new FormData();
    formData.append("EmployeeId", employee.Id);
    try {
      const response = await axios.post(
        get_project_available_balance,
        formData
      );
      setAvailableExpense(response?.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box p={4}>
      <SnackBar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
        status={status}
        color={color}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: "success.main" }}>
            <TableRow>
              <TableCell
                sx={{ color: "white", fontWeight: 500, textAlign: "center" }}
              >
                ID
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: 500, textAlign: "center" }}
              >
                Employee ID
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: 500 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Employee Name
                  <Filter
                    search={employeeNameSearch}
                    setSearch={setEmployeeNameSearch}
                    setPage={setPage}
                  />
                </Box>
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: 500 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Employee Balance
                  <Filter
                    search={employeeNameSearch}
                    setSearch={setEmployeeNameSearch}
                    setPage={setPage}
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: 500, textAlign: "center" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEmployees
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((employee) => (
                <TableRow key={employee.Id}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {employee.Id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {employee.EmployeeId}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {employee.EmployeeName}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {employee.EmployeeBalance}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={() => handleClickOpen(employee)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredEmployees.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog for Employee Details */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>Employee Details</DialogTitle>
        <DialogContent>
          {availbleExpense && availbleExpense.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ bgcolor: "success.main" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>
                      <strong>
                        Project Name{" "}
                        <Filter
                          search={dialogSearch}
                          setSearch={setDialogSearch} // Use the new state for dialog search
                        />
                      </strong>
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>
                      <strong>
                        Available Balance{" "}
                        <Filter
                          search={dialogSearch}
                          setSearch={setDialogSearch} // Use the new state for dialog search
                        />
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterProject(availbleExpense, dialogSearch).map(
                    (ele, index) => (
                      <TableRow key={index}>
                        <TableCell>{ele.ProjectName}</TableCell>
                        <TableCell>{ele.AvailableBalance}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", py: 2 }}>
              No Data Available
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmployeeTable;
