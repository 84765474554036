import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { HeadingContext } from "../Context/HeadingContext";

export default function Heading() {
  const { title } = useContext(HeadingContext);

  const stored = localStorage.getItem("title");

  const Head = title !== "" ? title : stored;

  


  return (
    <Typography
      variant="h3"
      sx={{
        textAlign: {
          xs: "center", // Center on mobile (xs)
          sm: "left",   // Left-align on larger screens (sm and above)
        },
        fontWeight: 600,
        fontSize: 25,
        textTransform: "uppercase",
        color: "#7bc54c",
       ml: {
          xs: 0,    // No left margin on mobile (xs)
          sm: 2.5,  // Margin on larger screens (sm and above)
        },
      }}
    >
      {Head}
      
    </Typography>
  );
}
