import React from "react";
import { Box, Card, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import ResponsiveBd from "./SideBar/ResponsiveBd";
import Heading from "./Heading";

function DashbrdHeader({ mobileOpen, setMobileOpen }) {
  const navigate = useNavigate();

  const responsive = ResponsiveBd();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    navigate("logout");
    localStorage.clear();
  };

  const userName = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      component={Card}
      py={2}
      display="flex"
      alignContent="center"
      square={true}
    >
      <Box>
        <IconButton
          aria-label="menu"
          sx={{
            display: responsive ? "block" : "none",
          }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Box width="100%">
      <Grid container justifyContent="space-between" alignItems="center">
  
  <Grid
    item
    xs={12}       
    sm={6}        
    display="flex"
    justifyContent={responsive ? "center" : "start"}   
    alignItems="center" 
  >
    <Box>
      <Heading />
    </Box>
  </Grid>

  {/* User greeting and icon section */}
  <Grid
    item
    xs={12}       
    sm={6}       
    display={responsive ? "none" : "flex"}  
    justifyContent="end"
    alignItems="center"
  >
    <Box display="flex" alignItems="center">
      <Box
        ml={1}
        sx={{
          fontWeight: 500,
          fontSize: 16,
          textTransform: "capitalize",
          color: "#7bc54c",
          mr: 2,
        }}
      >
        Hi, {userName}
      </Box>
      <Box mr={1}>
        <PowerSettingsNewIcon
          onClick={handleClick}
          color="error"
          sx={{ verticalAlign: "middle", cursor: "pointer" }}
        />
      </Box>
    </Box>
  </Grid>
</Grid>

      </Box>
    </Box>
  );
}

export default DashbrdHeader;
