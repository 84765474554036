import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Heading from "../Heading";
import Breadcrumbs from "../Breadcrumbs";
import {
  assignedProjectsempTable,
  LeaveApprovaltable,
  paysliptable,
} from "../../Variables/Variables";
import Filter from "../FilterData/Filter";
import { FilterData, FilterMaterial } from "../FilterData/FilterData";
import { useNavigate } from "react-router-dom";
import {
  get_all_factory_project,
  methodGet,
  get_project_for_store,
  getmaterial,
  getTechnicalFromCommercial,
} from "../../API_Service/API_Links";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import axios from "axios";
import SnackBar from "../SnackBar";
import TechnicalFromCommercialDialogPage from "./TechnicalFromCommercialDialog";

export default function TechnicalFromCommercialPage() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  const [openApproveDialog, setopenApproveDialog] = useState(false);
  const [dialogData, setDialogData] = useState();

  const [materialCodeSearch, setmaterialCodeSearch] = useState("");
  const [materialNameSearch, setmaterialNameSearch] = useState("");

  useEffect(() => {
    axios({
      method: "GET",
      url: getTechnicalFromCommercial,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setdata(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const statusMapping = {
    1: "In-Progress",
    2: "Purchase Pending",
    3: "Finance Pending",
    4: "Finance Approved",
    5: "Raw Material Available",
    6: "Assigned To Employee",
    7: "Completed",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (i) => {
    navigate("viewstore", { state: { id: i.ProjectId } });
  };

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "MaterialId");

  const UpdateStatus = (row) => {
    setDialogData(row);
    setopenApproveDialog(true);
  };

  //  const Reject  = (row) =>{
  //   const sendData = new FormData()
  //   sendData.append('Id', row.id)
  //   sendData.append('ExpenseStatus', 2)
  //   sendData.append('mainCategoryId', row.mainCategoryId)
  //   // sendData.append('EmployeeId', row.employeeId)
  //   // sendData.append('projectCode', row.projectName)
  //   sendData.append('Category1', row.mainCategory)
  //   // sendData.append('mainCategoryId', row.mainCategoryId)
  //   axios({
  //       method: methodPost,
  //       url: statusUpdateExpense,
  //       data:sendData
  //     })
  //       .then((res) => {
  //         if (!res.data.error) {
  //           setMessage(res.data.message);
  //           setStatus(true);
  //           setColor(true);
  //           setOpen(true);
  //           expenseStatus()
  //         } else {
  //           setMessage(res.data.message);
  //           setOpen(true)
  //           setStatus(false)
  //           setColor(false)
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Oops something went wrong " + err);
  //       });
  //  }

  return (
    <Box p={2}>
      <Box boxShadow={4} bgcolor="#EDF4F4" className="cardBoxshadow">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />
        <TechnicalFromCommercialDialogPage
          setMessage={setMessage}
          setStatus={setStatus}
          setOpen={setOpen}
          setColor={setColor}
          openApproveDialog={openApproveDialog}
          setopenApproveDialog={setopenApproveDialog}
          data={dialogData}
        />
        <Container>
          <Box mt={2} py={4}>
            <Grid container>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table>
                  <TableHead
                    sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                  >
                    <TableRow>
                      {keys.map((key) => (
                        <TableCell
                          sx={{
                            color: "white",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                          key={key}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{ my: "auto", textTransform: "uppercase" }}
                            >
                              {key.split(/(?=[A-Z])/).join(" ")}
                            </Box>
                            {key === "MaterialCode" && (
                              <Box>
                                <Filter
                                  search={materialCodeSearch}
                                  setSearch={setmaterialCodeSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                            {key === "MaterialName" && (
                              <Box>
                                <Filter
                                  search={materialNameSearch}
                                  setSearch={setmaterialNameSearch}
                                  setPage={setPage}
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: 500,
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        Raw Materials
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FilterMaterial(
                      data,
                      materialNameSearch,
                      materialCodeSearch
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          {keys.map((key) => (
                            <TableCell align="center" key={key}>
                              {row[key]}
                            </TableCell>
                          ))}
                          <TableCell align="center">
                            <Button
                              onClick={() => UpdateStatus(row, 1)}
                              variant="contained"
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  FilterMaterial(data, materialCodeSearch, materialNameSearch)
                    .length
                }
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

// const StoredSelectedTeam = () => {
//     // This is to display Team and Staff
//     const newObject = {
//         team: teamName,
//         staff: staffName,
//         StartDate: ProjectStart,
//         EndDate: ProjectEnd
//     };
//     setTeamMemberStaffPairs([...teamMemberStaffPairs, newObject]);
//     // This is to pass them to API
//     const convertedObject = {
//         EmployeeId: teamMember,
//         DepartmentId: staffMember,
//         StartDate: ProjectStart,
//         EndDate: ProjectEnd
//     }
//     setTeamSelectionAPI([...teamSelectionAPI, convertedObject]);
//     setTeamMember([]);
//     setStaffMember([]);
//     setProjectEnd('');
//     setProjectStart('');
//     setInput(true);
// }

{
  /* <Grid
    item
    lg={12}
    sm={12}
    xl={12}
    xs={12}
    md={12}
    sx={{ py: 1 }}
    display='flex'
    justifyContent='center'
>
    <Box sx={{ textAlign: "center" }}>
        {
            teamMemberStaffPairs.length !== 0 &&
            <>
                <Typography variant='h6' fontWeight={600}>Selected Team</Typography>
                {
                    teamMemberStaffPairs.map((i) => {
                        return (
                            <Box textAlign='left'>
                                <Typography variant='subtitle2'>{i.team} - {i.staff} : {i.StartDate} - {i.EndDate}</Typography>
                            </Box>

                        )
                    })
                }
            </>
        }
    </Box>
</Grid> */
}

// const [teamMemberStaffPairs, setTeamMemberStaffPairs] = useState([]);
// const [teamSelectionAPI, setTeamSelectionAPI] = useState([]);

// const [teamName, setTeamName] = useState([]);
// const [staffName, setStaffName] = useState([]);
// const [currentTeam, setCurrentTeam] = useState('');

{
  /* <Grid
    item
    lg={3}
    sm={2}
    xl={3}
    xs={2}
    md={3}
    sx={{ py: 2 }}
>
    <Button onClick={StoredSelectedTeam} variant='contained' fullWidth>+ Add</Button>
</Grid> */
}
