import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/colors/teal.css";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import axios from "axios";
import {
  insertYearlyCalendar,
  methodPost,
} from "../../../API_Service/API_Links";
import SnackBar from "../../SnackBar";

export default function CreateCalender() {
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <TextField
        onFocus={onFocus}
        size="small"
        value={value}
        onChange={onChange}
        label="Create Holiday"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  const formattedDates = values.map((date) => {
    const i = date.toDate();
    const year = i.getFullYear();
    const month = String(i.getMonth() + 1).padStart(2, "0");
    const day = String(i.getDate()).padStart(2, "0");

    const obj = {
      Date: `${year}-${month}-${day}`,
    };
    return obj;
  });

  const onSubmit = () => {
    const sendData = new FormData();
    sendData.append("dateList", JSON.stringify(formattedDates));

    axios({
      method: methodPost,
      url: insertYearlyCalendar,
      data: sendData,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  return (
    <Box p={2}>
      <Box
        p={4}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        component={Card}
      >
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <DatePicker
          multiple
          value={values}
          onChange={setValues}
          calendarPosition={"right-center"}
          fixMainPosition={true}
          sort
          numberOfMonths={3}
          highlightToday
          showOtherDays={false}
          hideOnScroll
          weekends={true}
          offsetX={-20}
          className="teal"
          render={<CustomInput />}
          dateSeparator=" & "
          plugins={[<DatePanel />, [weekends()]]}
        />

        <Button variant="contained" onClick={onSubmit}>
          Create Holiday Calendar
        </Button>

        <Box>
          <Typography variant="subitle2">
            Choose Holidays and Weekends. <strong>NOTE:</strong> Weekend that is
            not selected will be considered as working day.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
