import React, { useContext, useState } from "react";
import logo from "../../Assets/Images/logo.png";
import {
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import ResponsiveBd from "./ResponsiveBd";
import { sidebarItems } from "../../Variables/Variables";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { HeadingContext } from "../../Context/HeadingContext";

function SideBar({ setMobileOpen, mobileOpen }) {
  const responsive = ResponsiveBd();
  const drawerWidth = 280;

  // State for managing open/close of collapsible items
  const [open, setOpen] = useState({});
  const { setTitle } = useContext(HeadingContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (label) => {
    setOpen((prevOpen) => ({ ...prevOpen, [label]: !prevOpen[label] }));
    setTitle(label);
    localStorage.setItem("title", label);
  };

  const renderListItem = (item) => (
    <Link to={item.link || "#"} style={{ textDecoration: "none" }}>
      <ListItem
        button
        onClick={() => {
          setTitle(item.label);
          localStorage.setItem("title", item.label);
          handleDrawerToggle();
        }}
        sx={{ textAlign: "left", borderRadius: 2, py: 2, color: "#1e1e1e" }}
      >
        <ListItemIcon sx={{ px: 2, color: "#064420" }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText>
          <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
            {item.label}
          </Typography>
        </ListItemText>
      </ListItem>
    </Link>
  );

  const renderCollapseItem = (item, depth = 0) => (
    <Collapse in={open[item.label]} timeout="auto" unmountOnExit>
      <List sx={{ px: 2 }}>
        {item.subItems.map((subItem) => (
          <React.Fragment key={subItem.label}>
            {subItem.subItems ? (
              <>
                <ListItem
                  onClick={() => handleClick(subItem.label)}
                  button
                  sx={{
                    borderRadius: 2,
                    color: depth === 1 ? "#1e1e1e" : "#557571", // Color for subitems or top-level
                  }}
                >
                  <ListItemIcon
                    sx={{
                      px: 2,
                      color: depth === 1 ? "#1e1e1e" : "#064420", // Icon color based on depth
                    }}
                  >
                    {subItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.1rem",
                      color: depth === 1 ? "#1e1e1e" : "#557571", // Text color based on depth
                    }}
                  >
                    <Typography
                      component={"div"}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: 600,
                        fontSize: "1.1rem",
                      }}
                    >
                      {subItem.label}
                      <Box>
                        {open[subItem.label] ? <ExpandLess /> : <ExpandMore />}
                      </Box>
                    </Typography>
                  </ListItemText>
                </ListItem>
                {renderCollapseItem(subItem, depth + 1)}
              </>
            ) : (
              <Link
                to={subItem.link}
                className="text-nowrap text-decoration-none fs-5"
                key={subItem.label}
                sx={{ borderRadius: 2 }}
              >
                <ListItem
                  onClick={() => {
                    setTitle(subItem.label);
                    localStorage.setItem("title", subItem.label);
                    handleDrawerToggle();
                  }}
                  button
                  sx={{
                    borderRadius: 2,
                    color: depth === 1 ? "#1e1e1e" : "#557571", // Color for subitems or top-level
                  }}
                >
                  <ListItemIcon
                    sx={{
                      px: 2,
                      color: depth === 1 ? "#1e1e1e" : "#064420", // Icon color based on depth
                    }}
                  >
                    {subItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.1rem",
                      color: depth === 1 ? "#1e1e1e" : "#557571", // Text color based on depth
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: "1.1rem" }}>
                      {subItem.label}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            )}
          </React.Fragment>
        ))}
      </List>
    </Collapse>
  );

  const drawer = (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto", // Ensure scrollbars are visible
        scrollbarWidth: "thin",
        scrollbarColor: "#A7D7C5 #C6EBC5",
        "&::-webkit-scrollbar": {
          width: "12px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#C6EBC5",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#A7D7C5",
          borderRadius: "10px",
        },
      }}
    >
      {/* Logo */}
      <Box sx={{ px: 2 }}>
        <Box sx={{ textAlign: "center" }}>
          <img
            className="dashlogo"
            src={logo}
            alt="logo"
            style={{
              objectFit: "contain",
              height: "120px",
              width: "100%",
              verticalAlign: "top",
            }}
            loading="lazy"
          />
        </Box>
      </Box>

      {sidebarItems.map((item) => (
        <React.Fragment key={item.label}>
          {item.subItems ? (
            <>
              <ListItem
                onClick={() => handleClick(item.label)}
                button
                sx={{
                  textAlign: "left",
                  borderRadius: 2,
                  py: 2,
                  color: "#1e1e1e",
                }}
              >
                <ListItemIcon sx={{ px: 2, color: "#064420" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    component={"div"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "1.1rem",
                      }}
                    >
                      {item.label}
                    </Box>
                    <Box>
                      {open[item.label] ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                  </Typography>
                </ListItemText>
              </ListItem>
              {renderCollapseItem(item)}
            </>
          ) : (
            renderListItem(item)
          )}
        </React.Fragment>
      ))}
    </Box>
  );

  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? "temporary" : "permanent"}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: "block",
          width: drawerWidth,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "#C6EBC5",
            color: "#1e1e1e",
          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default SideBar;
