import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import {
  Reminderdropdown,
  add_announcement,
  getAllEmployeeName,
  getAllSiteProject,
  get_department,
  insertReminder,
  methodGet,
  methodPost,
} from "../../../API_Service/API_Links";
import { Form } from "react-bootstrap";
import axios from "axios";
import { appendData } from "../../../Variables/Variables";
import SnackBar from "../../SnackBar";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/colors/teal.css";

export default function CreateReminder() {
  // const options = ['Project Task', 'Non_Project Task'];

  const {
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [ProjectList, setProjectList] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [input, setInput] = useState(false);
  const [projectCode, setProjectCode] = useState(null);
  const navigate = useNavigate();
  const [empname, setEmpname] = useState([]);
  const [reminderType, setReminderType] = useState(null);
  const [EmpId, setEmpId] = useState(null);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [reminderDate, setReminderDate] = useState("");
  const [comments, setComments] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [emiAmount, setEmiAmount] = useState(0);
  const [fromTransaction, setFromTransaction] = useState("");
  const [allEmployeeName, setAllEmployeeName] = useState([]);
  const [Department, setDepartment] = useState(null);
  const [values, setValues] = useState([]);
  const [reminderTypeList, setReminderTypeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <TextField
        onFocus={onFocus}
        size="small"
        value={value}
        onChange={onChange}
        label="Reminder Dates"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  useEffect(() => {
    axios({
      method: "GET",
      url: get_department,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setStatus(true);
          setColor(true);
          setDepartmentList(res.data.data);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: Reminderdropdown,
    })
      .then((res) => {
        if (!res.data.error) {
          setReminderTypeList(res.data.data);
        } else {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllSiteProject,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setProjectList(res.data.data);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: methodGet,
      url: getAllEmployeeName,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setEmpname(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
        console.log("chip1", err);
      });
  }, []);

  const serverData = {
    ReminderType: reminderType,
    FromDate: StartDate,
    ToDate: EndDate,
    Comments: comments,
    Amount: totalAmount,
    EmployeeId: EmpId?.EmployeeId,
    ProjectCode: projectCode,
    ReminderDate: reminderDate,
    Department: Department?.DepartmentId,
  };
  const sendData = appendData(serverData);
  const onSubmit = () => {
    if (!navigator.onLine) {
      setMessage("Your internet is in Offline");
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      axios({
        method: methodPost,
        url: insertReminder,
        data: sendData,
      })
        .then((res) => {
          if (!res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setReminderType("");
            setStartDate("");
            setEndDate("");
            setComments("");
            setProjectCode(null);
            setReminderDate("");
            setEmpId(null);
            setDepartment(null);
            setTotalAmount("");
            setInput(true);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  };

  const cancelClick = () => {
    navigate("/home");
  };

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center">
        <SnackBar
          open={open}
          message={message}
          setOpen={setOpen}
          status={status}
          color={color}
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="center"
            sx={{ textAlign: "center" }}
            spacing={4}
          >
            <Grid item lg={12} xl={12}>
              <Box
                sx={{
                  border: "1px solid black",
                  px: 4,
                  pb: 1,
                  pt: 1,
                  mt: 4,
                  borderColor: "#d2cbcb;",
                  bgcolor: "#EDF4F4",
                  borderRadius: "4px",
                  ":hover": { boxShadow: 4 },
                }}
              >
                <Box sx={{ pb: 3, textAlign: "left" }}>
                  <h5>CREATE NEW REMINDER</h5>
                </Box>
                <Grid container justifyContent="start" spacing={2}>
                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <FormControl fullWidth size="small" color="secondary">
                      <InputLabel id="demo-select-small">
                        Reminder Type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        onChange={(e) => setReminderType(e.target.value)}
                        label="Reminder Type"
                        value={reminderType}
                      >
                        {reminderTypeList?.map((i) => {
                          <MenuItem value={i?.ReminderType}>
                            {i?.ReminderType}
                          </MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      key={input}
                      options={ProjectList.map((i) => i.projectCode)}
                      onChange={(event, value) => setProjectCode(value)}
                      size="small"
                      value={projectCode}
                      renderInput={(params) => (
                        <TextField
                          fontSize="small"
                          label="Project Code"
                          color="secondary"
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={departmentList}
                      value={Department}
                      getOptionLabel={(option) => option.Department}
                      onChange={(event, value) => setDepartment(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          sx={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                          color="secondary"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={empname}
                      value={EmpId}
                      getOptionLabel={(option) =>
                        `${option.EmployeeName} (${option.EmployeeId})`
                      }
                      onChange={(event, value) => setEmpId(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Resources"
                          sx={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                          color="secondary"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="StartDate"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      value={StartDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="EndDate"
                      label="End Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      value={EndDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Grid>

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <TextField
                      fullWidth
                      id="ProjectName"
                      label="Reminder Day"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      color="secondary"
                      type="date"
                      value={reminderDate}
                      onChange={(e) => setReminderDate(e.target.value)}
                    />
                  </Grid>

                  {/* <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2  }}>
                            <TextField 
                            fullWidth
                            id="ProjectName"
                            label="EMI Amount"
                            variant="outlined" 
                            size='small' 
                            color='secondary'
                            onChange={(e) => setEmiAmount(e.target.value)}
                            />
                        </Grid> */}

                  <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2 }}>
                    <TextField
                      fullWidth
                      id="ProjectName"
                      label="Amount"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      value={totalAmount}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.match(/^[0-9]+$/)) {
                          setTotalAmount(parseInt(newValue));
                        } else {
                          setTotalAmount("");
                        }
                      }}
                    />
                  </Grid>
                  {/* 
                        <Grid item lg={3} sm={6} xl={3} xs={12} md={3} sx={{ py: 2  }}>
                        <FormControl fullWidth size="small" color='secondary'>
                                <InputLabel id="demo-select-small">Transaction From</InputLabel>
                                <Select
                                 onChange={(e) => setFromTransaction(e.target.value)}
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    label="Transaction From" >
                                    <MenuItem value={'Igreen'}>Igreen</MenuItem>
                                    <MenuItem value={'Igreen Tec'}>Igreen Tec</MenuItem>
                                </Select>
                                </FormControl>
                        </Grid> */}

                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                    md={12}
                    sx={{ py: 2 }}
                  >
                    <TextField
                      fullWidth
                      id="ProjectName"
                      label="Comments"
                      variant="outlined"
                      multiline
                      size="small"
                      rows={3}
                      color="secondary"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  sx={{ textAlign: "center", mt: 1 }}
                >
                  <Grid item lg={6} xl={6} xs={12}>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Grid
                        item
                        lg={3}
                        sm={3}
                        xl={3}
                        xs={3}
                        md={3}
                        sx={{ py: 2 }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            fullWidth
                            variant="outlined"
                            onClick={cancelClick}
                            type="cancel"
                            sx={{
                              color: "white",
                              backgroundColor: "#c62828",
                              borderColor: "#c62828",
                              ":hover": {
                                borderColor: "#c62828",
                                color: "#000000",
                              },
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        sm={3}
                        xl={3}
                        xs={3}
                        md={3}
                        sx={{ py: 2 }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            fullWidth
                            variant="outlined"
                            type="submit"
                            sx={{
                              color: "white",
                              backgroundColor: "#7bc54c",
                              borderColor: "#7bc54c",
                              ":hover": {
                                borderColor: "#7bc54c",
                                color: "#000000",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Box>
  );
}
