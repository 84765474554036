import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField , Box, Grid, Typography} from '@mui/material';
import { FactoryDocs, QuoteDocs } from '../../API_Service/API_Links';

export default function QuotationDialog({ data, setQuotationData, setopenQuotationDialog, openQuotationDialog}) {

    const handleClose = () => {
        setopenQuotationDialog(false);
        setQuotationData([]);
    };
 

    return (
        <div>
            <Dialog
                open={openQuotationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ opacity: 1 }}
                onClose={handleClose}
            >
                <DialogTitle>Approve This Request</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">

                        <Box>

                            <Grid container display="flex" justifyContent='center' sx={{ textAlign: 'center' }} spacing={4} >
                                <Grid item lg={12} xl={12} >

                                    <Box sx={{ border: "1px solid black", px: 4, pb: 5, pt: 4, borderColor: '#d2cbcb;', borderRadius: '4px', ':hover': { boxShadow: 2 }, mt: 5 }}>
                                        <Box sx={{ pb: 3, textAlign: 'left' }}>
                                            <h5>VENDOR 1</h5>
                                        </Box>

                                        <Grid container justifyContent='space-evenly' spacing={2}  >
                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor_1 Name"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[0]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor GST 1"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[0]?.VendorGST}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor Address 1"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[0]?.VendorAddress}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Cost 1"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[0]?.Cost}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Lead Time 1"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[0]?.LeadTime}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <Typography variant='subtitle1' color='#616e80'>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={`${QuoteDocs}${data[0]?.QuoteAttachment}`}
                                                        target="_blank"
                                                    >
                                                        {data[0]?.QuoteAttachment}
                                                    </a>
                                                </Typography>
                                            </Grid>


                                        </Grid>

                                        <Box sx={{ pb: 3, textAlign: 'left' }}>
                                            <h5>VENDOR 2</h5>
                                        </Box>
                                        <Grid container justifyContent='space-evenly' spacing={2}  >
                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor_2 Name"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[1]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor GST 2"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[1]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor Address 2"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[1]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Cost 2"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[1]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Lead Time 2"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[1]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <Typography variant='subtitle1' color='#616e80'>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={`${FactoryDocs}${data[0]?.QuoteAttachment}`}
                                                        target="_blank"
                                                    >
                                                        {data[1]?.QuoteAttachment}
                                                    </a>
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                        <Box sx={{ pb: 3, textAlign: 'left' }}>
                                            <h5>VENDOR 3</h5>
                                        </Box>
                                        <Grid container justifyContent='space-evenly' spacing={2}  >
                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor_3 Name"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[2]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor GST 3"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[2]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Vendor Address 3"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[2]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Cost 3"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[2]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <TextField
                                                    fullWidth
                                                    id="ProjectName"
                                                    label="Lead Time 3"
                                                    variant="outlined"
                                                    size='small'
                                                    color='secondary'
                                                    value={data[2]?.VendorName}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item lg={4} sm={4} xl={4} xs={14} md={4} sx={{ py: 2 }}  >
                                                <Typography variant='subtitle1' color='#616e80'>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={`${FactoryDocs}${data[0]?.QuoteAttachment}`}
                                                        target="_blank"
                                                    >
                                                        {data[2]?.QuoteAttachment}
                                                    </a>
                                                </Typography>
                                            </Grid>


                                        </Grid>
                                    </Box>


                                </Grid >
                            </Grid>
                        </Box >


                    </Box >
                </DialogContent>
            </Dialog>
        </div>
    );
}