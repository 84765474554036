const FilterData = (data, search, searchfeild) => {

  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne?.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildTwo?.toString().toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}
const FilterDatatwo = (data, search, searchfeild) => {


  const { searchFeildOne, searchFeildTwo } = searchfeild;
  
  if (search === '') {
    return data
  } else if (searchFeildOne?.toString().includes(search)) {
    return data
  }
}
const FilterFund = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree, searchFeildFour, searchFeildFive } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildFour.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildFive.toString().toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}



// FilterData function now accepts separate filters for employeeId and projectCode
const FilterTwoData = (data, employeeIdSearch, projectCodeSearch) => {
  if (!employeeIdSearch && !projectCodeSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.employeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.projectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


const FilterSumCat = (data, employeeIdSearch, projectCodeSearch, spendDateSearch) => {
  if (!employeeIdSearch && !projectCodeSearch && !spendDateSearch) {
    return data;
  }

  return data.filter((row) => {
    console.log("row",row)
    if (employeeIdSearch && row.employeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.projectName
      .toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (spendDateSearch && row.mainCategory.toString().toLowerCase().includes(spendDateSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


const FilterMaterial = (data, materialNameSearch, materialCodeSearch) => {
  if (!materialNameSearch && !materialCodeSearch) {
    return data;
  }

  return data.filter((row) => {
    if (materialNameSearch && row.MaterialName.toString().toLowerCase().includes(materialNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (materialCodeSearch && row.MaterialCode.toString().toLowerCase().includes(materialCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


const FilterAssignedFactory = (data, projectNameSearch , projectTypeSearch) => {
  if (!projectNameSearch && !projectTypeSearch) {
    return data;
  }

  return data.filter((row) => {
    if (projectNameSearch && row.ProjectName.toString().toLowerCase().includes(projectNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectTypeSearch && row.ProjectType.toString().toLowerCase().includes(projectTypeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


const FilterAssignedSite = (data, projectIdSearch , teamSearch) => {
  if (!projectIdSearch && !teamSearch) {
    return data;
  }

  return data.filter((row) => {
    if (projectIdSearch && row.projectCode.toString().toLowerCase().includes(projectIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (teamSearch && row.Department.toString().toLowerCase().includes(teamSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};


const FilterFourData = (data, employeeIdSearch, employeeNameSearch, projectCodeSearch, categorySearch, dateSearch,createdSearch) => {
  if (!employeeIdSearch && !projectCodeSearch && !categorySearch && !dateSearch && !employeeNameSearch && !createdSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.employeeId && row.employeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.projectCode && row.projectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (categorySearch && row.category && row.category.toString().toLowerCase().includes(categorySearch.toLowerCase())) {
      return true; // Include in the filter if it matches the category search.
    }

    if (dateSearch && row.spendDate && row.spendDate.toString().toLowerCase().includes(dateSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }
    if (createdSearch && row.created_at && row.created_at.toString().toLowerCase().includes(createdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }

    if (employeeNameSearch && row.employeeName && row.employeeName.toString().toLowerCase().includes(employeeNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }

    return false; // Exclude if it doesn't match any of the search criteria.
  });
};
const FilterViewFund = (data, userNameSearch, employeeIdSearch, projectCodeSearch, createdSearch, statusSearch, updatedSearch) => {
  if (!userNameSearch && !employeeIdSearch && !projectCodeSearch && !createdSearch && !statusSearch && !updatedSearch) {
    return data;
  }

  return data.filter((row) => {
    if (userNameSearch && row.user_name && row.user_name.toString().toLowerCase().includes(userNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }
    if (employeeIdSearch && row.employee_id && row.employee_id.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.project_code && row.project_code.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (createdSearch && row.created_at && row.created_at.toString().toLowerCase().includes(createdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the category search.
    }

    if (statusSearch && row.status && row.status.toString().toLowerCase().includes(statusSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }
    if (updatedSearch && row.updated_at && row.updated.toString().toLowerCase().includes(updatedSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the date search.
    }

    return false; // Exclude if it doesn't match any of the search criteria.
  });
};

const FilterFundReq = (data, employeeIdSearch, userNameSearch, projectCodeSearch, createdSearch) => {
  if (!employeeIdSearch && !userNameSearch && !projectCodeSearch && !createdSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.employee_id && row.employee_id.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }
    if (userNameSearch && row.user_name && row.user_name.toString().toLowerCase().includes(userNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.project_code && row.project_code.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (createdSearch && row.created_at && row.created_at.toString().toLowerCase().includes(createdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the category search.
    }

    return false; // Exclude if it doesn't match any of the search criteria.
  });
};


const FilterReminder = (data, employeeIdSearch, projectCodeSearch, reminderStatusSearch) => {
  if (!employeeIdSearch && !projectCodeSearch && !reminderStatusSearch) {
    return data;
  }

  return data.filter((row) => {
    if (employeeIdSearch && row.EmployeeId.toString().toLowerCase().includes(employeeIdSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (projectCodeSearch && row.ProjectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (reminderStatusSearch && row.ReminderStatus.toString().toLowerCase().includes(reminderStatusSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    return false; // Exclude if it doesn't match either search.
  });
};



const FilterThreeData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
  if (search === '') {
    return data;
  } else if (
    (searchFeildOne && searchFeildOne.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFeildTwo && searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFeildThree && searchFeildThree.toString().toLowerCase().includes(search.toLowerCase()))
  ) {
    return data;
  }
};



const FilterSite = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}

  const filterEmpOrders = (data, search, searchfeild) => {
    const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
    if (search === '') {
      return data
    } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }

  const filterEmp = (data, search, searchfeild) => {
    console.log("setfields",searchfeild,data)
    const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
    if (search === '') {
      return data
    } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }


  const FilterLedger = (data, LedgerNameSearch, employeeNameSearch, projectCodeSearch, categorySearch, dateSearch, LedgerGroupsSearch) => {
    if (!LedgerNameSearch && !projectCodeSearch && !categorySearch && !dateSearch && !employeeNameSearch && !LedgerGroupsSearch) {
      return data;
    }
  
    return data.filter((row) => {
      if (LedgerNameSearch && row.LedgerName && row.LedgerName.toString().toLowerCase().includes(LedgerNameSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the employeeId search.
      }
  
      if (projectCodeSearch && row.ProjectCode && row.ProjectCode.toString().toLowerCase().includes(projectCodeSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the projectCode search.
      }
  
      if (categorySearch && row.Category1 && row.Category1.toString().toLowerCase().includes(categorySearch.toLowerCase())) {
        return true; // Include in the filter if it matches the category search.
      }
  
      if (dateSearch && row.ExpenseDate && row.ExpenseDate.toString().toLowerCase().includes(dateSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }
      if (LedgerGroupsSearch && row.LedgerGroups && row.LedgerGroups.toString().toLowerCase().includes(LedgerGroupsSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }
  
      if (employeeNameSearch && row.EmployeeName && row.EmployeeName.toString().toLowerCase().includes(employeeNameSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }
  
      return false; // Exclude if it doesn't match any of the search criteria.
    });
  };

  const FilterEditLedger = (data, LedgerNameSearch, LedgerGroupsSearch, LedgerCategory1Search, LedgerCategory2Search, LedgerCategory3Search,LedgerCategory4Search, LedgerCategory5Search) => {
    if (!LedgerNameSearch && !LedgerCategory1Search && !LedgerCategory2Search && !LedgerCategory3Search && !LedgerCategory4Search && !LedgerCategory5Search && !LedgerGroupsSearch) {
      return data;
    }
  
    return data.filter((row) => {
      if (LedgerNameSearch && row.LedgerName && row.LedgerName.toString().toLowerCase().includes(LedgerNameSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the employeeId search.
      }
  
      if (LedgerCategory1Search && row.LedgerCategory1 && row.LedgerCategory1.toString().toLowerCase().includes(LedgerCategory1Search.toLowerCase())) {
        return true; // Include in the filter if it matches the projectCode search.
      }
  
      if (LedgerCategory2Search && row.LedgerCategory2 && row.LedgerCategory2.toString().toLowerCase().includes(LedgerCategory2Search.toLowerCase())) {
        return true; // Include in the filter if it matches the category search.
      }
  
      if (LedgerCategory3Search && row.LedgerCategory3 && row.LedgerCategory3.toString().toLowerCase().includes(LedgerCategory3Search.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }

      if (LedgerCategory4Search && row.LedgerCategory4 && row.LedgerCategory4.toString().toLowerCase().includes(LedgerCategory4Search.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }

      if (LedgerCategory5Search && row.LedgerCategory5 && row.LedgerCategory5.toString().toLowerCase().includes(LedgerCategory5Search.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }

      if (LedgerGroupsSearch && row.LedgerGroups && row.LedgerGroups.toString().toLowerCase().includes(LedgerGroupsSearch.toLowerCase())) {
        return true; // Include in the filter if it matches the date search.
      }
  

  
      return false; // Exclude if it doesn't match any of the search criteria.
    });
  };


export {FilterDatatwo,filterEmp, FilterData,FilterMaterial , FilterLedger , FilterEditLedger  , FilterAssignedSite, FilterAssignedFactory , FilterReminder , FilterFourData, filterEmpOrders, FilterSite, FilterThreeData, FilterTwoData, FilterFund, FilterViewFund, FilterFundReq, FilterSumCat};