import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Heading from "../../Heading";
import Breadcrumbs from "../../Breadcrumbs";
import {
  assignedProjectsempTable,
  LeaveApprovaltable,
  paysliptable,
} from "../../../Variables/Variables";
import Filter from "../../FilterData/Filter";
import { FilterData, FilterReminder } from "../../FilterData/FilterData";
import { useNavigate } from "react-router-dom";
import {
  GetAllReminder,
  get_all_factory_project,
  methodGet,
  reminder,
} from "../../../API_Service/API_Links";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import axios from "axios";

export default function AllReminders() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    axios({
      method: "GET",
      url: GetAllReminder,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setdata(res.data.data);
          setOpen(true);
          setStatus(true);
          setColor(true);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  }, []);

  const statusMapping = {
    1: "In-Progress",
    2: "Purchase Pending",
    3: "Finance Pending",
    4: "Finance Approved",
    5: "Raw Material Available",
    6: "Assigned To Employee",
    7: "Completed",
  };

  const [employeeIdSearch, setEmployeeIdSearch] = useState("");
  const [projectCodeSearch, setProjectCodeSearch] = useState("");
  const [reminderStatusSearch, setreminderStatusSearch] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenEdit = (i) => {
    navigate("editFactproject", { state: { id: i.ProjectId } });
  };

  const handleOpenView = (i) => {
    navigate("viewFactproject", { state: { project: i } });
  };
  const keys =
    data && Object.keys(data[0] || {}).filter((key) => key !== "ReminderId");

  return (
    <Box p={2}>
     <Box bgcolor="#EDF4F4" py={2} className="cardBoxshadow">
        <Container>
          <Box mt={2} py={4}>
            <Grid container spacing={1}>
              <TableContainer sx={{ border: "1px solid silver" }}>
                <Table>
                  <TableHead
                    sx={{ whiteSpace: "nowrap", bgcolor: "success.main" }}
                  >
                    <TableRow>
                      {data &&
                        keys.map((key) => (
                          <TableCell
                            sx={{
                              color: "white",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                            key={key}
                          >
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <Box
                                sx={{ my: "auto", textTransform: "uppercase" }}
                              >
                                {key}
                              </Box>
                              {key === "EmployeeId" && (
                                <Box>
                                  <Filter
                                    search={employeeIdSearch}
                                    setSearch={setEmployeeIdSearch}
                                  />
                                </Box>
                              )}
                              {key === "ProjectCode" && (
                                <Box>
                                  <Filter
                                    search={projectCodeSearch}
                                    setSearch={setProjectCodeSearch}
                                  />
                                </Box>
                              )}
                              {key === "ReminderStatus" && (
                                <Box>
                                  <Filter
                                    search={reminderStatusSearch}
                                    setSearch={setreminderStatusSearch}
                                  />
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      FilterReminder(
                        data,
                        employeeIdSearch,
                        projectCodeSearch,
                        reminderStatusSearch
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={index}>
                            {keys.map((key) => (
                              <TableCell align="center" key={key}>
                                {key === "ReminderImage" ? (
                                  row[key] ? (
                                    <img
                                      src={`${reminder}${row[key]}`}
                                      style={{ width: 100, height: 100 }}
                                      alt={row[key]}
                                    />
                                  ) : (
                                    "-"
                                  )
                                ) : key === "ProofImage" ? (
                                  row[key] ? (
                                    <img
                                      src={`${reminder}${row[key]}`}
                                      style={{ width: 100, height: 100 }}
                                      alt={row[key]}
                                    />
                                  ) : (
                                    "-"
                                  )
                                ) : row[key] ? (
                                  row[key]
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  data
                    ? FilterReminder(
                        data,
                        employeeIdSearch,
                        projectCodeSearch,
                        reminderStatusSearch
                      ).length
                    : 0
                }
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
