import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Container,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { appendData } from "../../../Variables/Variables";
import axios from "axios";
import {
  AddBankBalance,
  getBankDropdown,
  insertAccountDetails,
  methodPost,
} from "../../../API_Service/API_Links";
import { useState } from "react";
import SnackBar from "../../SnackBar";
import { useEffect } from "react";

export default function BalanceCheck() {
  const [ImageProof, setImageProof] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [Account, setAccount] = useState([]);
  const [SelectedAccount, setSelectedAccount] = useState(null);
  const [data, setData] = useState({
    ClientName: "",
    Comments: "",
    Type: "",
    Amount: "",
    AccountHolder: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (
      data?.AccountHolder !== "" &&
      data?.AccountHolder !== null &&
      data?.AccountHolder !== undefined
    ) {
      const sendData = new FormData();
      sendData.append("AccountHolder", data?.AccountHolder);
      axios({
        method: "POST",
        url: getBankDropdown,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(false);
            setColor(false);
            setAccount([]);
          } else {
            setMessage(res.data.message);
            setOpen(true);
            setStatus(true);
            setColor(true);
            setAccount(res.data.data);
          }
        })
        .catch((err) => {
          alert("Oops something went wrong " + err);
        });
    }
  }, [data?.AccountHolder]);

  const AddNewBal = () => {
    const obj = {
      accountDetailsId: SelectedAccount?.accountDetailsId,
      AccountHolder: data.AccountHolder,
      ClientName: data.ClientName,
      Comments: data.Comments,
      EntryBased: data.Type,
      Amount: data.Amount,
      Images: ImageProof,
    };
    const ldata = appendData(obj);
    axios({
      method: methodPost,
      url: AddBankBalance,
      data: ldata,
    })
      .then((res) => {
        if (!res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          setData({
            ClientName: "",
            Comments: "",
            Type: "",
            Amount: "",
            AccountHolder: null,
          });
          document.getElementById("Imageproof").value = "";
          setSelectedAccount(null);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  return (
    <Box p={2}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        status={status}
        color={color}
      />

      <Container>
        <Box component={Card} p={4} className="cardBoxshadow">
          <Box
            sx={{
              border: "1px solid black",
              p: 3,
              borderColor: "#d2cbcb;",
              borderRadius: "4px",
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={data.AccountHolder}
                  options={["igreen", "igreentec"]}
                  onChange={(event, value) =>
                    setData({ ...data, AccountHolder: value })
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Account Holder"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={SelectedAccount}
                  options={Account}
                  getOptionLabel={(option) =>
                    `${option.account_number}(${option.AliasName})`
                  }
                  onChange={(event, value) => setSelectedAccount(value)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Account"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="ClientName"
                  label="Client Name"
                  fullWidth
                  size="small"
                  value={data.ClientName}
                  sx={{ my: 1 }}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Comments"
                  label="Comments"
                  fullWidth
                  size="small"
                  value={data.Comments}
                  onChange={handleChange}
                  sx={{ my: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={data.Type}
                  options={["Income", "Loan", "Capital"]}
                  onChange={(event, value) => setData({ ...data, Type: value })}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ my: 1 }}
                      fontSize="small"
                      label="Type"
                      color="secondary"
                      {...params}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Amount"
                  label="Amount"
                  fullWidth
                  sx={{ my: 1 }}
                  value={data.Amount}
                  size="small"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^[0-9]*\.?[0-9]*$/.test(newValue)) {
                      setData({ ...data, Amount: newValue });
                    } else {
                      setData({ ...data, Amount: "" });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  name="Imageproof"
                  label="Upload Image"
                  type="file"
                  id="Imageproof"
                  onChange={(e) => setImageProof(e.target.files[0])}
                  fullWidth
                  size="small"
                  sx={{ my: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="center" mt={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                display="flex"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center" py={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={AddNewBal}
                    fullWidth
                  >
                    Add Balance
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
